import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { rootApi } from '../../../../api';
import { useGetCustomersWithoutPriceListsQuery, useGetOrdersDashboardQuery } from '../../../../api/dashboard';
import { useGeHiddenPages } from '../../../../api/teammates/hooks';
import SvgCalculateIcon from '../../../../assets/icons/CalculateIcon';
import SvgChooseStore from '../../../../assets/icons/ChooseStore';
import SvgCustomerIcon from '../../../../assets/icons/CustomerIcon';
import SvgDashboardIcon from '../../../../assets/icons/DashboardIcon';
import SvgFavorite from '../../../../assets/icons/Favorite';
import SvgFavoriteFill from '../../../../assets/icons/FavoriteFill';
import SvgListIcon from '../../../../assets/icons/ListIcon';
import SvgOrderIcon from '../../../../assets/icons/OrderIcon';
import SvgProductIcon from '../../../../assets/icons/ProductIcon';
import SvgReportIcon from '../../../../assets/icons/ReportIcon';
import { CustomerSearchIcon } from '../../../../shared/components/customer-search-icon';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getInventorySortFilters, setInventoryFilter } from '../../../../store/inventory';
import { showPantryListExitWarning } from '../../../../store/pantry-lists';
import { setPantryListProductFilter } from '../../../../store/pantry-lists/pantry-lists.actions';
import { getPantryListProductFilter } from '../../../../store/pantry-lists/pantry-lists.selectors';
import { clearProductsList, getProductSortFilters, setProductsFilterBy } from '../../../../store/products';
import { showRecOrderExitWarning } from '../../../../store/recurring-order';
import { getImpersonatedCustomer, getMySubscriptionType } from '../../../../store/user';
import { useCurrentTheme } from '../../../../api/admin-themes/hooks';
import { ThemeColors } from '../../../../api/admin-themes/types';
import { SubscriptionPlanEnum } from '../../../../api/user/types';
import SvgHistoryIcon from '../../../../assets/icons/HistoryIcon';

import { useRippleStyles } from '../../../../styles/customRipple';
import { useStyles } from './style';

interface Props {
  isRestaurant: boolean;
}

const Component: React.FC<Props> = ({ isRestaurant }) => {
  const { colors } = useCurrentTheme();
  const classes = useStyles({ textColor: colors[ThemeColors.navBarText], bgColor: colors[ThemeColors.navBarBg] });
  const rippleClass = useRippleStyles();
  const dispatch = useAppDispatch();
  const {
    state: navState,
  }: {
    state: { recOrderId?: number; pantryListId?: number; redirect?: string; supplier_id?: number } | null;
  } = useLocation();
  const { push, goBack } = useHistory();
  const { pathname } = useLocation();

  const isInventoryPage = pathname.includes('/tools/inventory');
  const isPantryListPage = pathname.includes('/pantry_list/details');
  const isMenuHidden = !!useGeHiddenPages('menu_planning');
  const isPantryListHidden = !!useGeHiddenPages('pantry_lists');

  const { filter } = useAppSelector(getProductSortFilters);
  const { filter: inventoryFilter } = useAppSelector(getInventorySortFilters);
  const { favourites, ...pantryFilter } = useAppSelector(getPantryListProductFilter);
  const impersonatedCustomer = useAppSelector(getImpersonatedCustomer);
  const plan = useAppSelector(getMySubscriptionType);

  const [isSearchOpened, setIsSearchOpened] = useState(false);

  const { customersCount } = useGetCustomersWithoutPriceListsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      customersCount: data?.customers?.length,
    }),
    skip: isRestaurant,
  });

  const { data: orders } = useGetOrdersDashboardQuery(undefined, {
    skip: isRestaurant,
  });

  const onCounterClick = (link: string) => {
    if (link === '/orders') {
      dispatch(rootApi.util.invalidateTags(['Order']));
    }
    if (link === '/customers') {
      dispatch(rootApi.util.invalidateTags(['Customer']));
    }
  };

  const openSearch = () => {
    if (isPantryListPage) {
      push('/pantry_list/details/search');
      setIsSearchOpened(true);
      dispatch(
        setPantryListProductFilter({
          ...pantryFilter,
          supplier_id: undefined,
          keyword: undefined,
        }),
      );
    } else {
      push(/\/browse_by_supplier\/(\d+)\/products/.test(pathname) ? `/browse_by_supplier/${filter?.supplier_id}/search` : '/categories', {
        redirect: pathname,
        pantryListId: navState?.pantryListId,
        recOrderId: navState?.recOrderId,
      });
    }
  };

  const closeSearch = () => {
    if (isPantryListPage) {
      setIsSearchOpened(false);
      goBack();
      dispatch(
        setPantryListProductFilter({
          ...pantryFilter,
          keyword: undefined,
        }),
      );
      return;
    }
    navState?.redirect
      ? push(navState?.redirect, {
          supplier_id: navState?.supplier_id,
          pantryListId: navState?.pantryListId,
          recOrderId: navState?.recOrderId,
        })
      : goBack();
    dispatch(clearProductsList());
    LocalStorageService.clear('products_filter');
  };

  const closeSearchOnReload = useCallback(() => {
    if (isPantryListPage) {
      return;
    }
    if (!/\/browse_by_supplier\/(\d+)\/search/.test(pathname)) {
      push('/categories');
      dispatch(clearProductsList());
      LocalStorageService.clear('products_filter');
    }
  }, [pathname]);

  const toggleFavorites = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (isInventoryPage) {
      dispatch(
        setInventoryFilter(
          inventoryFilter
            ? {
                ...inventoryFilter,
                favourite: !inventoryFilter.favourite,
              }
            : { favourite: true },
        ),
      );
    } else if (isPantryListPage) {
      dispatch(
        setPantryListProductFilter({
          ...pantryFilter,
          favourites: !favourites,
        }),
      );
    } else {
      dispatch(setProductsFilterBy({ ...filter, favourites: !filter?.favourites }));
    }
  };

  const onTabClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (navState?.recOrderId || navState?.pantryListId || impersonatedCustomer) {
      e.preventDefault();
      navState?.recOrderId &&
        dispatch(
          showRecOrderExitWarning({
            handler: push.bind(null, e.currentTarget.pathname),
          }),
        );
      navState?.pantryListId &&
        dispatch(
          showPantryListExitWarning({
            handler: push.bind(null, e.currentTarget.pathname),
          }),
        );
    }
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', closeSearchOnReload);
    };
  }, []);

  useEffect(() => {
    setIsSearchOpened(
      !!navState?.redirect || /\/browse_by_supplier\/(\d+)\/search/.test(pathname) || pathname.includes('pantry_list/details/search'),
    );
    if (navState?.redirect || /\/browse_by_supplier\/(\d+)\/search/.test(pathname)) {
      window.addEventListener('beforeunload', closeSearchOnReload);
    } else {
      window.removeEventListener('beforeunload', closeSearchOnReload);
    }
  }, [navState?.redirect, /\/browse_by_supplier\/(\d+)\/search/.test(pathname), pathname.includes('pantry_list/details/search')]);

  return (
    <div className={clsx(classes.container, isRestaurant && classes.customerContainer)}>
      {isRestaurant ? (
        plan === SubscriptionPlanEnum.NORMAL ? (
          <>
            <NavLink
              onClick={onTabClick}
              className={clsx([
                classes.item,
                rippleClass.ripple,
                classes.itemCustomer,
                impersonatedCustomer && classes.itemCustomerDisabled,
              ])}
              to='/browse_by_supplier'
              activeClassName={classes.itemActiveCustomer}
            >
              <SvgChooseStore height={21} width={24} className={clsx(classes.menuIcon, classes.menuIconCustomer)} />
              <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Home</span>
            </NavLink>
            <NavLink
              onClick={onTabClick}
              className={clsx([
                classes.item,
                rippleClass.ripple,
                classes.itemCustomer,
                (impersonatedCustomer || isPantryListHidden) && classes.itemCustomerDisabled,
              ])}
              to='/pantry_list'
              activeClassName={classes.itemActiveCustomer}
              exact={true}
            >
              <SvgListIcon className={clsx(classes.menuIcon, classes.menuIconCustomer)} />
              <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Pantry List</span>
            </NavLink>
            <div className={classes.searchIconBox}>
              <CustomerSearchIcon isSearchOpened={isSearchOpened} toggleSearch={isSearchOpened ? closeSearch : openSearch} />
            </div>
            <NavLink
              onClick={onTabClick}
              className={clsx([classes.item, rippleClass.ripple, classes.itemCustomer])}
              to='/orders_history'
              activeClassName={classes.itemActiveCustomer}
              exact={true}
            >
              <SvgHistoryIcon height={22} width={25} className={clsx(classes.menuIcon, classes.menuIconCustomer)} />
              <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Order History</span>
            </NavLink>

            {isInventoryPage ? (
              <NavLink
                className={clsx([
                  classes.item,
                  rippleClass.ripple,
                  classes.itemCustomer,
                  inventoryFilter?.favourite && classes.itemFavoritesActiveCustomer,
                ])}
                to='#'
                exact={true}
                onClick={toggleFavorites}
              >
                {inventoryFilter?.favourite ? (
                  <SvgFavoriteFill className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} />
                ) : (
                  <SvgFavorite className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} color={'#4E5468'} />
                )}
                <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Favourites</span>
              </NavLink>
            ) : isPantryListPage ? (
              <NavLink
                className={clsx([
                  classes.item,
                  rippleClass.ripple,
                  classes.itemCustomer,
                  favourites && classes.itemFavoritesActiveCustomer,
                ])}
                to='#'
                exact={true}
                onClick={toggleFavorites}
              >
                {favourites ? (
                  <SvgFavoriteFill className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} />
                ) : (
                  <SvgFavorite className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} color={'#4E5468'} />
                )}
                <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Favourites</span>
              </NavLink>
            ) : (
              <NavLink
                className={clsx([
                  classes.item,
                  rippleClass.ripple,
                  classes.itemCustomer,
                  filter?.favourites && classes.itemFavoritesActiveCustomer,
                ])}
                to='#'
                exact={true}
                onClick={toggleFavorites}
              >
                {filter?.favourites ? (
                  <SvgFavoriteFill className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} />
                ) : (
                  <SvgFavorite className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} color={'#4E5468'} />
                )}
                <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Favourites</span>
              </NavLink>
            )}
          </>
        ) : (
          <>
            <NavLink
              onClick={onTabClick}
              className={clsx([
                classes.item,
                rippleClass.ripple,
                classes.itemCustomer,
                impersonatedCustomer && classes.itemCustomerDisabled,
              ])}
              to='/browse_by_supplier'
              activeClassName={classes.itemActiveCustomer}
            >
              <SvgChooseStore height={21} width={24} className={clsx(classes.menuIcon, classes.menuIconCustomer)} />
              <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Choose a Store</span>
            </NavLink>
            <NavLink
              onClick={onTabClick}
              className={clsx([
                classes.item,
                rippleClass.ripple,
                classes.itemCustomer,
                (impersonatedCustomer || isPantryListHidden) && classes.itemCustomerDisabled,
              ])}
              to='/pantry_list'
              activeClassName={classes.itemActiveCustomer}
              exact={true}
            >
              <SvgListIcon className={clsx(classes.menuIcon, classes.menuIconCustomer)} />
              <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Pantry List</span>
            </NavLink>
            <div className={classes.searchIconBox}>
              <CustomerSearchIcon isSearchOpened={isSearchOpened} toggleSearch={isSearchOpened ? closeSearch : openSearch} />
            </div>
            <NavLink
              onClick={onTabClick}
              className={clsx([
                classes.item,
                rippleClass.ripple,
                classes.itemCustomer,
                (impersonatedCustomer || isMenuHidden) && classes.itemCustomerDisabled,
              ])}
              to={'/menu_costing'}
              activeClassName={classes.itemActiveCustomer}
            >
              <SvgCalculateIcon className={clsx(classes.menuIcon, classes.menuIconCustomer)} />
              <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Menu Costing</span>
            </NavLink>

            {isInventoryPage ? (
              <NavLink
                className={clsx([
                  classes.item,
                  rippleClass.ripple,
                  classes.itemCustomer,
                  inventoryFilter?.favourite && classes.itemFavoritesActiveCustomer,
                ])}
                to='#'
                exact={true}
                onClick={toggleFavorites}
              >
                {inventoryFilter?.favourite ? (
                  <SvgFavoriteFill className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} />
                ) : (
                  <SvgFavorite className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} color={'#4E5468'} />
                )}
                <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Favourites</span>
              </NavLink>
            ) : isPantryListPage ? (
              <NavLink
                className={clsx([
                  classes.item,
                  rippleClass.ripple,
                  classes.itemCustomer,
                  favourites && classes.itemFavoritesActiveCustomer,
                ])}
                to='#'
                exact={true}
                onClick={toggleFavorites}
              >
                {favourites ? (
                  <SvgFavoriteFill className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} />
                ) : (
                  <SvgFavorite className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} color={'#4E5468'} />
                )}
                <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Favourites</span>
              </NavLink>
            ) : (
              <NavLink
                className={clsx([
                  classes.item,
                  rippleClass.ripple,
                  classes.itemCustomer,
                  filter?.favourites && classes.itemFavoritesActiveCustomer,
                ])}
                to='#'
                exact={true}
                onClick={toggleFavorites}
              >
                {filter?.favourites ? (
                  <SvgFavoriteFill className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} />
                ) : (
                  <SvgFavorite className={clsx(classes.menuIcon, classes.menuIconCustomer, classes.starIcon)} color={'#4E5468'} />
                )}
                <span className={clsx(classes.linkText, classes.linkTextCustomer)}>Favourites</span>
              </NavLink>
            )}
          </>
        )
      ) : (
        <>
          <NavLink className={clsx([classes.item, rippleClass.ripple])} to='/dashboard' activeClassName={classes.itemActive} exact={true}>
            <SvgDashboardIcon className={classes.menuIcon} />
            <span className={classes.linkText}>Dashboard</span>
          </NavLink>
          <NavLink className={clsx([classes.item, rippleClass.ripple])} to='/customers' activeClassName={classes.itemActive}>
            <SvgCustomerIcon className={classes.menuIcon} />
            <span className={classes.linkText}>Customers</span>
            {!!customersCount && (
              <span className={classes.counter} onClick={onCounterClick.bind(null, '/customers')}>
                {customersCount}
              </span>
            )}
          </NavLink>
          <NavLink className={clsx([classes.item, rippleClass.ripple])} to='/products' activeClassName={classes.itemActive}>
            <SvgProductIcon className={classes.menuIcon} />
            <span className={classes.linkText}>Products</span>
          </NavLink>
          <NavLink className={clsx([classes.item, rippleClass.ripple])} to='/orders' activeClassName={classes.itemActive}>
            <SvgOrderIcon className={classes.menuIcon} />
            <span className={classes.linkText}>Orders</span>
            {!!orders?.total_new_orders && (
              <span className={classes.counter} onClick={onCounterClick.bind(null, '/orders')}>
                {orders.total_new_orders}
              </span>
            )}
          </NavLink>
          <NavLink className={clsx([classes.item, rippleClass.ripple])} to='/tools' activeClassName={classes.itemActive}>
            <SvgReportIcon className={classes.menuIcon} />
            <span className={classes.linkText}>Tools</span>
          </NavLink>
        </>
      )}
    </div>
  );
};

export const BottomBar = React.memo(Component);
