import { useLocation } from 'react-router-dom';

import useTimeout from '../../shared/hooks/use-timeout';
import { useAppDispatch, useAppSelector } from '../../store';
import { getOrdersSortFilters, setOrdersKeyword, setOrdersSortFilter } from '../../store/orders';
import { getIsRestaurant } from '../../store/user';
import { useGetOrderByIdQuery, useGetOrdersQuery } from './index';

export const useGetOrders = () => {
  const { keyword, currentPage, filter, sort, ordersType } = useAppSelector(getOrdersSortFilters);
  const { orders, loading, totalPages } = useGetOrdersQuery(
    { keyword, currentPage, filter, sort, ordersType },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        orders: data?.orders || [],
        loading: isLoading || isFetching,
        totalPages: data?.total_pages || 0,
      }),
    },
  );
  return { orders, loading, totalPages };
};

export const useSelectOrderById = (id: number | null) => {
  const { keyword, currentPage, filter, sort } = useAppSelector(getOrdersSortFilters);
  const { order } = useGetOrdersQuery(
    { keyword, currentPage, filter, sort },
    {
      selectFromResult: ({ data }) => ({
        order: data?.orders?.find((el) => el.id === id),
      }),
      skip: !id,
    },
  );
  return { order };
};

export const useGetOrderById = (id?: number) => {
  const { data: order } = useGetOrderByIdQuery(id, {
    skip: !id,
  });
  return { order };
};

export const useSetOrdersDefaults = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isRestaurant = useAppSelector(getIsRestaurant);
  const { keyword, filter } = useAppSelector(getOrdersSortFilters);
  useTimeout(
    () => {
      dispatch(setOrdersKeyword(''));
    },
    (pathname.includes('/orders') && !isRestaurant) || (pathname.includes('/orders_history') && isRestaurant) || !keyword ? null : 300000,
  );
  if (!pathname.includes('/orders_history') && isRestaurant && filter) {
    dispatch(setOrdersSortFilter({ filter: undefined }));
  }
};
