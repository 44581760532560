import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../../../assets/images/logo/Logo_White.svg';
import { ThemedButton } from '../../../shared/components/themed-button';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getIsImpersonated } from '../../../store/user';
import { backToAdminThunk } from '../../../store/user/user.actions';
import { OnBoardSteps } from '../Onboarding';
import OnboardManualSupplierNew from '../Onboarding/onboard-manual-supplier-details';
import OnboardManualSupplierTemplate from '../Onboarding/onboard-manual-supplier-template';

import { useStyles } from './style';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';

const OnBoardManualSupplier: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { replace } = useHistory();
  const { logoUrl } = useCurrentTheme();

  const impersonated = useAppSelector(getIsImpersonated);
  const [onBoardStep, setOnBoardStep] = useState<OnBoardSteps | null>(OnBoardSteps.MANUAL_INFO);

  const onBackToAdmin = () => {
    dispatch(backToAdminThunk());
  };

  useEffect(() => {
    if (onBoardStep === OnBoardSteps.MANUAL_UPLOAD || onBoardStep === OnBoardSteps.MANUAL_INFO) {
      return;
    }
    replace('/account/suppliers/add', { addForm: true });
  }, [onBoardStep]);

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <div className={classes.logoBox}>
          <img src={logoUrl || Logo} alt='OpenPantry' className={classes.logo} style={{ objectFit: 'contain', maxHeight: 70 }} />
        </div>
        {onBoardStep === OnBoardSteps.MANUAL_INFO && <OnboardManualSupplierNew setOnboardStep={(step) => setOnBoardStep(step)} />}
        {onBoardStep === OnBoardSteps.MANUAL_UPLOAD && (
          <OnboardManualSupplierTemplate
            setOnboardStep={() => {
              replace('/account/suppliers/add', { addForm: true });
            }}
            openNextCategory={() => {
              replace('/account/suppliers');
            }}
          />
        )}
      </div>
      {impersonated && (
        <ThemedButton
          onClick={onBackToAdmin}
          title='Admin View'
          buttonStyle='greenTransparent'
          isSmall={true}
          customClass={classes.backToAdmin}
          width={140}
        />
      )}
    </div>
  );
};

export default OnBoardManualSupplier;
