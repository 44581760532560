import { createAction } from '@reduxjs/toolkit';

import { ProductFilterValues, ProductsSortBy, UpdateManualSupplierProduct } from '../../api/product/types';

export const setManualProductsSortBy = createAction<ProductsSortBy | undefined>('SET_MANUAL_PRODUCTS_SORT_BY');

export const setManualProductsFilterBy = createAction<ProductFilterValues | undefined>('SET_MANUAL_PRODUCTS_FILTER_BY');

export const setManualProductsPage = createAction<number>('SET_MANUAL_PRODUCTS_PAGE');

export const setManualProductsKeyword = createAction<{ keyword?: string }>('SET_MANUAL_PRODUCTS_KEYWORD');

export const clearManualProductsList = createAction('CLEAR_M_PRODUCTS_LIST');

export const setManualProductsUpdateData = createAction<UpdateManualSupplierProduct>('SET_MANUAL_PRODUCT_UPDATE_DATA');

export const clearManualProductsUpdateData = createAction('CLEAR_MANUAL_PRODUCT_UPDATE_DATA');
