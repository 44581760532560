import { createAction } from '@reduxjs/toolkit';

export const setRecipeKeyword = createAction<string>('SET_RECIPE_KEYWORD');

export const setEditRecipeIngredientsMode = createAction<boolean>('SET_EDIT_RECIPE_INGREDIENTS_MODE');

export const setMenuCostingVideoModalOpened = createAction<boolean>('SET_MENU_COSTING_MODAL_OPENED');

export const setRpsVideoModalOpened = createAction<boolean>('SET_RPS_MODAL_OPENED');

export const setEditedRecipeId = createAction<number | null>('SET_EDITED_RECIPE_ID');

export const setRecipeBatchSize = createAction<number>('SET_RECIPE_BATCH_SIZE');

export const addNewIngredientId = createAction<{ id: number; type: 'product' | 'recipe' }>('ADD_NEW_INGREDIENT_ID');

export const removeNewIngredientId = createAction<{ id: number; type: 'product' | 'recipe' }>('REMOVE_NEW_INGREDIENT_ID');

export const clearNewIngredientIds = createAction('CLEAR_NEW_INGREDIENT_IDS');

export const setIngredientsQtyRounded = createAction<boolean>('SET_INGREDIENTS_QTY_ROUNDED');

export const setNestedQuantityRoundStatus = createAction<0 | 1>('SET_NESTED_QUANTITY_ROUND_STATUS');

export const setSelectedRecipeTag = createAction<number>('SET_SELECTED_RECIPE_TAG');

export const clearSelectedRecipeTags = createAction('CLEAr_SELECTED_RECIPE_TAGS');

export const setSelectAlternativeMode = createAction<null | { type: 'product' | 'sub-recipe'; id: number }>('SET_SELECT_ALTERNATIVE_MODE');

export const setReplaceMode = createAction<null | { type: 'product' | 'sub-recipe'; id: number; product_id: number }>('SET_REPLACE_MODE');

export const setCreateAlternativeMode = createAction<null | { type: 'product' | 'sub-recipe'; id: number; product_id: number }>(
  'SET_CREATE_ALTERNATIVE_MODE',
);

export const setSelectedSubRecipeTag = createAction<number>('SET_SELECTED_SUB_RECIPE_TAG');

export const setAlternativeId = createAction<number>('SET_ALTERNATIVE_ID');

export const clearSelectedSubRecipeTags = createAction('CLEAr_SELECTED_SUB_RECIPE_TAGS');

export const setSubRecipesExpanded = createAction<boolean>('SET_SUB_RECIPES_EXPANDED');

export const setExpandedIngredient = createAction<number | null>('SET_EXPANDED_INGREDIENT');

export const setSelectedArchivedRecipeTag = createAction<number>('SET_SELECTED_ARCHIVED_RECIPE_TAG');

export const clearSelectedArchivedRecipeTags = createAction('CLEAR_SELECTED_ARCHIVED_RECIPE_TAGS');

export const setSearchByTagState = createAction<{ menu: boolean; sub: boolean; archive: boolean }>('SET_SEARCH_RECIPE_BY_TAG_STATE');
