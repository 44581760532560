import React, { useEffect, useState } from 'react';

import { ClickAwayListener, Tooltip } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { Field, FieldProps, Form, Formik } from 'formik';
import LinesEllipsis from 'react-lines-ellipsis';
import { CommonButton } from '../../../../../shared/components/common-button';
import { useHistory, useLocation } from 'react-router-dom';
import { useAcceptConnectionRequestMutation, useDeclineConnectionRequestMutation } from '../../../../../api/company';
import { CustomerConnectionStatus } from '../../../../../api/customer/types';
import { useGetSupplierListQuery } from '../../../../../api/supplier';
import { useGetAvailableCategories } from '../../../../../api/teammates/hooks';
import { ConnectionRequest } from '../../../../../api/user/types';
import { BlurredImage } from '../../../../../shared/components/blurred-image';
import { CloseButton } from '../../../../../shared/components/close-button';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { FormikDropdown } from '../../../../../shared/components/formik-dropdown';
import { InfoTooltip } from '../../../../../shared/components/info-tooltip';
import { ProductsCategories } from '../../../../../shared/constants/products';
import { getCategoriesFromIds, getCategoryId } from '../../../../../shared/helpers/getCategoryId';
import { setGtmDatalayer } from '../../../../../shared/helpers/setGtmDatalayer';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../shared/services/toastService';

import { useStyles } from './style';

interface Props {
  item: ConnectionRequest;
  onSetRequestId: (data: { requestId: number; categoryId: number }) => void;
  selectedCategories?: ProductsCategories[];
}

export const ConnectionReqItem: React.FC<Props> = ({ item, onSetRequestId, selectedCategories }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const isVerified = item.supplier.verified;
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [isToolTipShown, setIsToolTipShown] = useState(false);
  const [isVerifiedTooltipShown, setIsVerifiedTooltipShown] = useState(false);
  const [isVerifiedTextShown, setIsVerifiedTextShown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ProductsCategories | null>(null);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const availableCategories = useGetAvailableCategories();

  const { supplierCategories } = useGetSupplierListQuery(
    {
      pending_supplier: true,
      keyword: '',
      per_page: 200,
    },
    {
      selectFromResult: ({ data }) => ({
        supplierCategories: data?.suppliers?.find((sp) => sp.id === item?.supplier.id)?.categories || [],
      }),
    },
  );

  const [acceptRequest, { isLoading: acceptReqLoading, isSuccess: acceptSuccess }] = useAcceptConnectionRequestMutation();
  const [declineRequest, { isLoading: declineReqLoading }] = useDeclineConnectionRequestMutation();

  const showTooltip = () => {
    setIsVerifiedTooltipShown(true);
  };

  const hideTooltip = () => {
    setIsVerifiedTooltipShown(false);
  };

  const showVerificationText = () => {
    hideTooltip();
    setIsVerifiedTextShown(true);
  };

  const onSubmit = ({ category }: { category: string }) => {
    setGtmDatalayer({
      event: 'supplier_connection_request',
      eventCategory: 'select_category',
      eventAction: category,
    });
    setSelectedCategory(category as ProductsCategories);
    if (!selectedCategories) {
      setIsToolTipShown(false);
      setDisabledSubmit(false);
      onSetRequestId({ requestId: item.id, categoryId: getCategoryId(category as ProductsCategories) });
      return;
    }
    if (selectedCategories?.find((cat) => cat === category)) {
      setIsToolTipShown(false);
      onSetRequestId({ requestId: item.id, categoryId: getCategoryId(category as ProductsCategories) });
      setDisabledSubmit(false);
    } else {
      setDisabledSubmit(true);
      onSetRequestId({ requestId: item.id, categoryId: 0 });
      setIsToolTipShown(true);
    }
  };

  const onAccept = () => {
    if (item && selectedCategory) {
      acceptRequest({
        connection_request: {
          id: item.id,
          status: CustomerConnectionStatus.ACCEPTED,
          connection_categories_attributes: [
            {
              category_id: getCategoryId(selectedCategory),
            },
          ],
        },
      });
    }
  };

  const onDecline = () => {
    declineRequest({
      connection_request_id: item.id,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const setCategoryAndClose = (category: ProductsCategories) => {
    if (Array.isArray(availableCategories) && !availableCategories.includes(category)) {
      ToastService.error('You have been restricted from selecting this category.');
      handleClose();
      return;
    }
    if (!selectedCategories) {
      setIsToolTipShown(false);
      setSelectedCategory(category);
      setDisabledSubmit(false);
      onSetRequestId({ requestId: item.id, categoryId: getCategoryId(category as ProductsCategories) });
      handleClose();
      return;
    }
    if (selectedCategories?.find((cat) => cat === category)) {
      setIsToolTipShown(false);
      setSelectedCategory(category);
      setDisabledSubmit(false);
      onSetRequestId({ requestId: item.id, categoryId: getCategoryId(category as ProductsCategories) });
    } else {
      setIsToolTipShown(true);
      onSetRequestId({ requestId: item.id, categoryId: 0 });
    }
    handleClose();
  };

  useEffect(() => {
    if (acceptSuccess) {
      setDisabledSubmit(true);
      if (pathname.includes('/browse_by_supplier/') && pathname.endsWith('/products')) {
        push('/browse_by_supplier');
      }
    }
  }, [acceptSuccess]);

  useEffect(() => {
    acceptSuccess && setDisabledSubmit(true);
  }, [acceptSuccess]);

  return (
    <div className={classes.row}>
      <div className={classes.companyInfo}>
        <div className={classes.avatarWrap}>
          <BlurredImage src={item.supplier_company?.picture_url || ''} />
        </div>
        <div className={classes.fullWidth}>
          <div className={clsx(classes.boldText, classes.fullWidth)}>
            <LinesEllipsis text={item.supplier_company?.name || ''} maxLine={1} ellipsis='...' />
          </div>
          <div className={classes.greyText}>
            {item.supplier?.company_addresses[0]?.city && item.supplier.company_addresses[0]?.state
              ? `${item.supplier.company_addresses[0].city.slice(0, 20)}, ${item.supplier.company_addresses[0].state.toUpperCase()}`
              : ''}
          </div>
        </div>
        {isToolTipShown && (
          <ClickAwayListener onClickAway={setIsToolTipShown.bind(null, false)}>
            <div className={classes.tooltipWrap}>
              <InfoTooltip text='Unable to add this supplier here. Please add this category.' rightSide={!isMobile} />
            </div>
          </ClickAwayListener>
        )}
      </div>
      <div className={classes.dropdownWrap}>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            category: '',
          }}
          enableReinitialize={true}
        >
          <Form className={classes.form}>
            <Field name='category'>
              {(fieldProps: FieldProps) => (
                <FormikDropdown
                  submitOnChange={true}
                  placeholder='Select category'
                  options={(supplierCategories.length
                    ? getCategoriesFromIds(supplierCategories)
                    : selectedCategories
                    ? selectedCategories
                    : Object.values(ProductsCategories)
                  ).map((cat) => ({
                    label: cat,
                    value: cat,
                  }))}
                  {...fieldProps}
                  customClasses={{
                    customSelectClass: classes.customSelectBtn,
                    customFormControlClass: classes.customSelectFormControl,
                    placeholderClass: classes.customPlaceholder,
                  }}
                />
              )}
            </Field>
          </Form>
        </Formik>
      </div>
      <div className={classes.btnBlock}>
        <div className={classes.btnWrap}>
          {item.status === CustomerConnectionStatus.ACCEPTED ? (
            isMobile ? (
              <CommonButton
                onClick={() => {}}
                title='Accepted'
                isSmall={true}
                width={96}
                endIcon={<KeyboardArrowDownIcon />}
                className={classes.acceptedBtnStyle}
              />
            ) : (
              <div className={classes.acceptedText}>Accepted</div>
            )
          ) : (
            <ClickAwayListener onClickAway={hideTooltip}>
              <Tooltip
                disableTouchListener={true}
                disableHoverListener={true}
                disableFocusListener={true}
                arrow={true}
                classes={{
                  tooltip: classes.rootTooltip,
                  arrow: classes.arrow,
                }}
                title={
                  <div className={classes.tooltip}>
                    <div className={classes.tooltipTitle}>Supplier is not "verified"</div>
                    <div className={classes.tooltipText}>
                      You will be able to accept the connection request after our verification of this supplier.
                    </div>
                    <CommonButton onClick={showVerificationText} title='OK' isSmall={true} width={200} className={classes.redBtn} />
                    <CloseButton customClass={classes.closeBtn} onClick={hideTooltip} />
                  </div>
                }
                interactive={true}
                open={isVerifiedTooltipShown}
                onOpen={showTooltip}
                onClose={hideTooltip}
                placement='top-end'
              >
                <div onClick={!isVerified ? showTooltip : undefined}>
                  {isMobile ? (
                    <ThemedButton
                      onClick={(e) => {
                        if (selectedCategory) {
                          isVerified ? onAccept() : showTooltip();
                        } else {
                          isVerified ? handleOpen(e) : showTooltip();
                        }
                      }}
                      title='Accept'
                      isSmall={true}
                      width={96}
                      endIcon={!selectedCategory ? <KeyboardArrowDownIcon /> : undefined}
                      disabled={acceptReqLoading || disabledSubmit || isVerifiedTextShown}
                    />
                  ) : (
                    <ThemedButton
                      onClick={isVerified ? onAccept : () => {}}
                      title='Accept'
                      isSmall={true}
                      width={100}
                      disabled={!selectedCategory || acceptReqLoading || disabledSubmit || isVerifiedTextShown}
                    />
                  )}
                </div>
              </Tooltip>
            </ClickAwayListener>
          )}
          {item.status === CustomerConnectionStatus.ACCEPTED ? (
            <ThemedButton
              onClick={onDecline}
              title='Disconnect'
              isSmall={true}
              buttonStyle='secondary'
              width={isMobile ? 96 : 100}
              disabled={declineReqLoading}
            />
          ) : (
            <ThemedButton
              onClick={onDecline}
              title='Ignore'
              isSmall={true}
              buttonStyle='secondary'
              width={isMobile ? 96 : 100}
              disabled={declineReqLoading || isVerifiedTextShown}
            />
          )}
        </div>
        {isVerifiedTextShown && <div className={classes.verificationText}>Supplier on the verification</div>}
      </div>
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {(supplierCategories.length
          ? getCategoriesFromIds(supplierCategories)
          : selectedCategories
          ? selectedCategories
          : Object.values(ProductsCategories)
        ).map((category) => (
          <MenuItem onClick={setCategoryAndClose.bind(null, category)} classes={{ root: classes.menuItem }} key={category}>
            {category}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
