import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  AddPrepListToCartReq,
  AddRecipeProductToCartReq,
  AddRecipeToCartReq,
  CancelReservedReq,
  EstimatedChangeMultiplier,
  PreparationList,
  PreparationListCreate,
  PreparationListQueryParams,
  PreparationListUpdate,
  PreparationListWithRecipes,
  PrepListByIdRes,
  PrepListChangeMultiplier,
  PrepListDeleteRecipe,
  PrepListSetReserved,
  PrepListsRes,
  WeekDayRes,
} from './types';
import { setSuccessToast } from '../helpers';
import { setAppSuccessToast } from '../../store/user';

export const prepListApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrepLists: builder.query<PrepListsRes, PreparationListQueryParams>({
      query: (params) => {
        return {
          url: `/preparation_lists`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Prep_Lists'],
    }),

    getPrepListWeekDaysInfo: builder.query<WeekDayRes, { id?: number; start_date: string; end_date: string }>({
      query: ({ id, ...params }) => {
        return {
          url: `/preparation_lists/${id}/check_week_days`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Prep_List'],
    }),

    getPrepListById: builder.query<PrepListByIdRes, { id?: string; week_date?: string }>({
      query: ({ id, week_date }) => {
        return {
          url: `/preparation_lists/${id}`,
          method: HTTP.GET,
          params: {
            week_date,
          },
        };
      },
      providesTags: ['Prep_List'],
    }),

    createPrepList: builder.mutation<{ preparation_list: PreparationList; success: boolean }, PreparationListCreate>({
      query: (body) => {
        return {
          url: `/preparation_lists`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Prep_Lists'],
    }),

    updatePrepList: builder.mutation<{ preparation_list: PreparationList; success: boolean }, { body: PreparationListUpdate; id: number }>({
      query: ({ body, id }) => {
        return {
          url: `/preparation_lists/${id}`,
          method: HTTP.PATCH,
          body: { preparation_list: body },
        };
      },
      invalidatesTags: ['Prep_List', 'Prep_Lists'],
    }),

    deleteRecipeFromPrepList: builder.mutation<{ preparation_list: PreparationListWithRecipes; success: boolean }, PrepListDeleteRecipe>({
      query: ({ recipe_id, id }) => {
        return {
          url: `/preparation_lists/${id}`,
          method: HTTP.PATCH,
          body: { preparation_list: { list_recipes_attributes: [{ id: recipe_id, _destroy: true }] } },
        };
      },
      invalidatesTags: ['Prep_Lists', 'Prep_List'],
    }),

    setPrepListReserved: builder.mutation<PrepListByIdRes, PrepListSetReserved>({
      query: ({ data, id }) => {
        return {
          url: `/preparation_lists/${id}/update_week_day_recipe`,
          method: HTTP.PATCH,
          body: data,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          if (!data?.week_day) {
            return;
          }
          patchResult = dispatch(
            prepListApi.util.updateQueryData(
              'getPrepListById',
              { id: data.week_day.preparation_list_id.toString(), week_date: arg.data.week_date },
              (draft) => {
                draft.week_day = data.week_day;
              },
            ),
          );
        } catch {
          patchResult?.undo();
        }
      },
    }),

    changePrepListMultiplier: builder.mutation<PrepListByIdRes, PrepListChangeMultiplier>({
      query: ({ data, id }) => {
        return {
          url: `/preparation_lists/${id}/update_week_day_recipe`,
          method: HTTP.PATCH,
          body: data,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          if (!data?.week_day) {
            return;
          }
          patchResult = dispatch(
            prepListApi.util.updateQueryData(
              'getPrepListById',
              { id: data.week_day.preparation_list_id.toString(), week_date: arg.data.week_date },
              (draft) => {
                draft.week_day = data.week_day;
              },
            ),
          );
        } catch {
          patchResult?.undo();
        }
      },
    }),

    changeEstimatedMultiplier: builder.mutation<PrepListByIdRes, EstimatedChangeMultiplier>({
      query: ({ data, id }) => {
        return {
          url: `/preparation_lists/${id}/update_estimated`,
          method: HTTP.PATCH,
          body: data,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // need to review
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          if (!data?.week_day) {
            return;
          }
          patchResult = dispatch(
            prepListApi.util.updateQueryData(
              'getPrepListById',
              { id: data.week_day.preparation_list_id.toString(), week_date: arg.data.week_date },
              (draft) => {
                draft.week_day = data.week_day;
              },
            ),
          );
        } catch {
          patchResult?.undo();
        }
      },
    }),

    deletePrepList: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/preparation_lists/${id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Preparation list has been deleted');
      },
      invalidatesTags: ['Prep_Lists'],
    }),

    cancelReserved: builder.mutation<SuccessResponse, CancelReservedReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/preparation_lists/${id}/cancel_reserve`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Prep_List'],
    }),

    cancelReservedAndCart: builder.mutation<SuccessResponse, CancelReservedReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/preparation_lists/${id}/cancel_reserve_and_cart`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Prep_List', 'Cart'],
    }),

    addRecipeToCart: builder.mutation<SuccessResponse, AddRecipeToCartReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/preparation_lists/${id}/cart_add_recipe`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Prep_List', 'Cart', 'Prep_Lists'],
    }),

    addRecipeProductToCart: builder.mutation<SuccessResponse, AddRecipeProductToCartReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/preparation_lists/${id}/cart_add_product`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Prep_List', 'Cart', 'Prep_Lists'],
    }),

    addAllPrepListToCart: builder.mutation<SuccessResponse, AddPrepListToCartReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/preparation_lists/${id}/cart_add_list`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'All products have been added to cart and reserved.');
      },
      invalidatesTags: ['Prep_List', 'Cart', 'Prep_Lists'],
    }),

    updatePrepListDays: builder.mutation<SuccessResponse, { id: string; week_date: string; set_day: string }>({
      query: ({ id, ...body }) => {
        return {
          url: `/preparation_lists/${id}/update_set_day`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Prep_List', 'Prep_Lists'],
    }),

    exportPrepListPDF: builder.query<any, { id: number, week_date: string }>({
      query: ({ id, week_date }) => {
        return {
          url: `/preparation_lists/${id}/export_prep_list`,
          method: HTTP.GET,
          params: {
            export_pdf: true,
            week_date,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          res?.data?.success && dispatch(setAppSuccessToast('Preparation List have been exported.'));
        } catch (e) {
          console.log(e);
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),

  overrideExisting: true,
});

export const {
  usePrefetch,
  useCreatePrepListMutation,
  useGetPrepListsQuery,
  useDeletePrepListMutation,
  useGetPrepListByIdQuery,
  useUpdatePrepListMutation,
  useAddRecipeToCartMutation,
  useAddAllPrepListToCartMutation,
  useSetPrepListReservedMutation,
  useChangePrepListMultiplierMutation,
  useChangeEstimatedMultiplierMutation,
  useDeleteRecipeFromPrepListMutation,
  useUpdatePrepListDaysMutation,
  useAddRecipeProductToCartMutation,
  useCancelReservedMutation,
  useCancelReservedAndCartMutation,
  useGetPrepListWeekDaysInfoQuery,
  useLazyExportPrepListPDFQuery,
} = prepListApi;
