import React, { useCallback, useEffect, useState } from 'react';

import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import ReactDropzone from 'react-dropzone';
import LinesEllipsis from 'react-lines-ellipsis';

import {
  useExportManualSupplierProductsMutation,
  useGetIsFirstDownloadQuery,
  useImportManualSupplierProductsMutation,
  usePreviewManualSupplierProductsMutation,
} from '../../../../../api/product';
import { ManualSupplierAction } from '../../../../../api/supplier/types';
import SvgFile from '../../../../../assets/icons/File';
import SvgUploadIcon from '../../../../../assets/icons/UploadIcon';
import { ButtonLoader } from '../../../../../shared/components/button-loader';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { OverlayModal } from '../../../../../shared/components/overlay-modal';
import { ProgressBar } from '../../../../../shared/components/progress-bar';
import { generateProductsSheetForManual } from '../../../../../shared/helpers/generateProductsSheetForManual';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getManualSupplierAction, setManualSupplierAction } from '../../../../../store/suppliers-categories';
import { ImportManualProductPreview } from '../../../OnboardManualSupplier/import-preview';
import { PreviewProducts } from '../../../OnboardManualSupplier/preview-products';

import { useStyles } from './style';
import { setAppSuccessToast } from '../../../../../store/user';

enum ImportSteps {
  DOWNLOAD,
  PREVIEW,
  IMPORT,
}

export const UploadPricingModal: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const [importStep, setImportStep] = useState<ImportSteps>(ImportSteps.DOWNLOAD);
  const [fileName, setFileName] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadPercent, setUploadPercent] = useState(0);

  const manualSupplierAction = useAppSelector(getManualSupplierAction);
  const isOpen = manualSupplierAction?.action === ManualSupplierAction.UPLOAD;
  const supplierId = manualSupplierAction?.id;
  const { data: downloadData } = useGetIsFirstDownloadQuery(supplierId, { skip: !supplierId });
  const [firstlyDownload, setFirstlyDownload] = useState<boolean>(downloadData?.firstly_download || true);
  const [getData, { data, error, isLoading }] = usePreviewManualSupplierProductsMutation();
  const [importProducts, { isLoading: importLoading }] = useImportManualSupplierProductsMutation();
  const [exportTemplate] = useExportManualSupplierProductsMutation();

  const closeModal = () => {
    dispatch(setManualSupplierAction(null));
    setImportStep(ImportSteps.DOWNLOAD);
    setFileName('');
    setUploadedFile(null);
    setUploadPercent(0);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadedFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
  }, []);

  const onUpload = () => {
    if (uploadedFile && supplierId) {
      const xlsxData = new FormData();
      xlsxData.append('file', uploadedFile);
      xlsxData.append('supplier_id', supplierId.toString());
      setImportStep(ImportSteps.PREVIEW);
      getData(xlsxData);
    }
  };

  const onImport = () => {
    if (uploadedFile && supplierId) {
      const xlsxData = new FormData();
      xlsxData.append('file', uploadedFile);
      xlsxData.append('supplier_id', supplierId.toString());

      dispatch(setManualSupplierAction({ id: supplierId, action: ManualSupplierAction.VIEW }));
      setImportStep(ImportSteps.DOWNLOAD);
      setFileName('');
      setUploadedFile(null);
      setUploadPercent(0);

      importProducts(xlsxData).then((res) => {
        if ('data' in res && res?.data?.success) {
          dispatch(setAppSuccessToast('Products have been imported successfully'));
        }
      });
    }
  };

  const onExportManualProducts = async () => {
    const res = await exportTemplate(supplierId as number);
    if ('data' in res) {
      await generateProductsSheetForManual(firstlyDownload ? undefined : res?.data);
      setFirstlyDownload(false);
    }
  };

  useEffect(() => {
    if (importStep !== ImportSteps.PREVIEW || error) {
      setUploadPercent(0);
      return;
    }
    const uploadTimer = setInterval(() => {
      setUploadPercent((state) => {
        if (state < 95) {
          return state + 5;
        } else {
          clearInterval(uploadTimer);
          return state;
        }
      });
      if (!isLoading) {
        setUploadPercent(100);
        clearInterval(uploadTimer);
      }
    }, 700);
  }, [importStep, isLoading]);

  return (
    <OverlayModal
      isOpen={isOpen}
      onClose={closeModal}
      animate={true}
      boxClassName={clsx({
        [classes.modalRoot1]: importStep === ImportSteps.DOWNLOAD,
        [classes.modalRoot2]: importStep === ImportSteps.PREVIEW || importStep === ImportSteps.IMPORT,
      })}
    >
      {importStep === ImportSteps.DOWNLOAD && (
        <div className={classes.box}>
          <div className={classes.title}>Upload your prices with this Supplier</div>
          {firstlyDownload ? (
            <div className={classes.subTitle}>
              Please download our price list template, then upload your products and prices for this supplier.
            </div>
          ) : (
            <div className={classes.subTitle}>
              Please download our price list template, then upload your products and prices for this supplier. Note that you can update your
              prices by uploading the products again with the updated price
            </div>
          )}
          <div className={classes.uploadContainerWrapM}>
            <ReactDropzone onDrop={onDrop} accept='.xlsx'>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div className={clsx(classes.uploadContainer, isDragActive && classes.focused)} {...getRootProps()}>
                  <SvgUploadIcon className={classes.uploadIcon} />
                  <input {...getInputProps()} />
                  <div className={classes.uploadDescription}>
                    {!fileName ? (
                      !firstlyDownload ? (
                        <span>
                          Drop file here to upload or <b className={classes.link}>choose a file</b>
                        </span>
                      ) : (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          Please download our template{' '}
                          <b className={classes.link} onClick={onExportManualProducts}>
                            here
                          </b>
                        </span>
                      )
                    ) : (
                      <span>{fileName}</span>
                    )}
                  </div>
                </div>
              )}
            </ReactDropzone>
          </div>
          <ThemedButton
            onClick={onExportManualProducts}
            title={firstlyDownload ? 'Download template' : 'Download Product List'}
            buttonStyle='white'
            customClass={classes.downTemplateBtn}
          />
          <div>
            <ThemedButton onClick={closeModal} title='Back' buttonStyle='secondary' width={isMobile ? 130 : 215} />
            <ThemedButton onClick={onUpload} title='Upload' width={isMobile ? 130 : 215} disabled={!uploadedFile} />
          </div>
        </div>
      )}
      {importStep === ImportSteps.PREVIEW && (
        <div className={classes.box}>
          <div className={classes.title}>Upload the completed template</div>
          <div className={classes.subTitle}>Once you’ve added all of your data, save your spreadsheet and upload as </div>
          <div className={classes.formats}>
            <div>.XLSX</div>
          </div>
          <>
            <div className={classes.uploadProgressContainer}>
              <SvgFile className={classes.fileIcon} />
              <div>
                <div className={classes.topBox}>
                  <div className={classes.boldText}>{fileName}</div>
                  {error || (data?.added?.length === 0 && data?.changed?.length === 0) ? (
                    <div className={classes.error}>
                      {data?.added?.length === 0 && data?.changed?.length === 0 ? 'List is empty' : 'Errors in file data'}
                    </div>
                  ) : uploadPercent < 100 || isLoading ? (
                    <div>{uploadPercent === 100 ? 99 : uploadPercent}%</div>
                  ) : (
                    <div>
                      {isMobile ? `${uploadPercent}%` : 'Products Loaded Successfully'} <DoneIcon className={classes.doneIcon} />
                    </div>
                  )}
                </div>
                <ProgressBar percent={uploadPercent} />
              </div>
            </div>
            {uploadPercent === 100 && data && (
              <>
                <div className={classes.errorsBlockWrap}>
                  <div className={classes.errorsBlock}>
                    {data?.error_data.length ? (
                      <LinesEllipsis
                        text={`${data?.error_data.length} File errors: ${data.error_data
                          .slice(0, 10)
                          .map((el) =>
                            'field_errors' in el
                              ? Object.entries(Object.values(el.field_errors)[0]).reduce((acc, [field, err]) => {
                                  acc += `${field} - ${err}, `;
                                  return acc;
                                }, '')
                              : '',
                          )
                          .join(' ')}`}
                        maxLine={2}
                        ellipsis='...'
                      />
                    ) : null}
                  </div>
                </div>
                <div className={classes.previewWrap}>
                  <PreviewProducts data={data} />
                </div>
              </>
            )}
          </>
          <div className={classes.btnsBox}>
            <ThemedButton
              onClick={setImportStep.bind(null, ImportSteps.DOWNLOAD)}
              title='Back'
              buttonStyle='secondary'
              width={isMobile ? 130 : 215}
            />
            <ThemedButton
              onClick={() => {
                setImportStep(ImportSteps.IMPORT);
              }}
              title={isMobile ? 'Preview' : 'Preview your data'}
              width={isMobile ? 130 : 215}
              disabled={isLoading || !(!!data?.added?.length || !!data?.changed?.length)}
            />
          </div>
        </div>
      )}
      {importStep === ImportSteps.IMPORT && (
        <>
          <div className={classes.title}>Upload your prices with this Supplier</div>
          <div className={classes.subTitle}>
            Double check to make sure your data is being uploaded into the correct Open Pantry fields. <br /> Once imported this step cannot
            be undone
          </div>
          <div className={classes.previewWrap}>
            <ImportManualProductPreview data={data ? [...data.added, ...data.changed].slice(0, isMobile ? 1 : 4) : []} />
          </div>
          <div className={classes.btnsBox}>
            <ThemedButton
              onClick={() => {
                setImportStep(ImportSteps.DOWNLOAD);
                setFileName('');
                setUploadedFile(null);
              }}
              title={isMobile ? 'Try again' : 'Try uploading again'}
              width={isMobile ? 130 : 215}
              buttonStyle='secondary'
              disabled={importLoading}
            />
            <ThemedButton
              onClick={onImport}
              title={importLoading ? 'Loading...' : isMobile ? 'Save' : 'I’m happy with this'}
              width={isMobile ? 130 : 215}
              disabled={importLoading}
              endIcon={importLoading ? <ButtonLoader /> : null}
            />
          </div>
        </>
      )}
    </OverlayModal>
  );
};
