import { createReducer } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';
import { menuApi } from '../../api/menu';
import { logoutThunk } from '../user/user.actions';
import {
  addNewIngredientId,
  clearNewIngredientIds,
  clearSelectedArchivedRecipeTags,
  clearSelectedRecipeTags,
  clearSelectedSubRecipeTags,
  removeNewIngredientId,
  setAlternativeId,
  setCreateAlternativeMode,
  setEditedRecipeId,
  setEditRecipeIngredientsMode,
  setExpandedIngredient,
  setIngredientsQtyRounded,
  setMenuCostingVideoModalOpened,
  setNestedQuantityRoundStatus,
  setRecipeBatchSize,
  setRecipeKeyword,
  setReplaceMode,
  setRpsVideoModalOpened,
  setSearchByTagState,
  setSelectAlternativeMode,
  setSelectedArchivedRecipeTag,
  setSelectedRecipeTag,
  setSelectedSubRecipeTag,
  setSubRecipesExpanded,
} from './menu.actions';

interface State {
  keyword?: string;
  editIngredientsMode: boolean;
  editedRecipeId: number | null;
  batchSize: number;
  newIngredientsIds: { id: number; type: 'product' | 'recipe' }[];
  searchByTagState: { menu: boolean; sub: boolean; archive: boolean };
  selectedTagsIds: number[];
  selectedSubRecTagsIds: number[];
  selectedArchivedRecTagsIds: number[];
  quantityRounded: boolean;
  nestedQuantityRoundStatus: 0 | 1;
  selectAlternativeModeFor: null | { type: 'product' | 'sub-recipe'; id: number };
  replaceModeData: null | { type: 'product' | 'sub-recipe'; id: number; product_id: number };
  createAlternativeData: null | { type: 'product' | 'sub-recipe'; id: number; product_id: number };
  alternativeId: number | null;
  videoModalShown: boolean;
  rpsVideoModalShown: boolean;
  subRecipesExpanded: boolean;
  expandedIngredient: null | number;
}

const initialState: State = {
  keyword: undefined,
  editIngredientsMode: false,
  editedRecipeId: null,
  batchSize: 1,
  newIngredientsIds: [],
  selectedTagsIds: [],
  selectedSubRecTagsIds: [],
  selectedArchivedRecTagsIds: [],
  searchByTagState: { menu: false, sub: false, archive: false },
  quantityRounded: false,
  nestedQuantityRoundStatus: 0,
  selectAlternativeModeFor: null,
  replaceModeData: null,
  createAlternativeData: null,
  videoModalShown: false,
  rpsVideoModalShown: true,
  subRecipesExpanded: false,
  expandedIngredient: null,
  alternativeId: null,
};

export const menuReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return { ...initialState, filterBy: undefined };
  });
  builder.addCase(setRecipeKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
  });
  builder.addCase(setEditRecipeIngredientsMode, (state, { payload }) => {
    state.editIngredientsMode = payload;
  });
  builder.addCase(setEditedRecipeId, (state, { payload }) => {
    state.editedRecipeId = payload;
  });
  builder.addCase(setRecipeBatchSize, (state, { payload }) => {
    state.batchSize = payload;
  });
  builder.addCase(setIngredientsQtyRounded, (state, { payload }) => {
    state.quantityRounded = payload;
  });
  builder.addCase(setSubRecipesExpanded, (state, { payload }) => {
    state.subRecipesExpanded = payload;
  });
  builder.addCase(setMenuCostingVideoModalOpened, (state, { payload }) => {
    state.videoModalShown = payload;
  });
  builder.addCase(setRpsVideoModalOpened, (state, { payload }) => {
    state.rpsVideoModalShown = payload;
  });
  builder.addCase(addNewIngredientId, (state, { payload }) => {
    state.selectAlternativeModeFor || state.replaceModeData || state.createAlternativeData
      ? (state.newIngredientsIds = [payload])
      : state.newIngredientsIds.push(payload);
  });
  builder.addCase(removeNewIngredientId, (state, { payload }) => {
    remove(state.newIngredientsIds, (el) => el.id === payload.id && el.type === payload.type);
  });
  builder.addCase(clearNewIngredientIds, (state) => {
    state.newIngredientsIds = [];
  });
  builder.addCase(setNestedQuantityRoundStatus, (state, { payload }) => {
    state.nestedQuantityRoundStatus = payload;
  });
  builder.addCase(setSelectedRecipeTag, (state, { payload }) => {
    if (state.selectedTagsIds.includes(payload)) {
      remove(state.selectedTagsIds, (el) => el === payload);
    } else {
      state.selectedTagsIds.push(payload);
    }
  });
  builder.addCase(clearSelectedRecipeTags, (state) => {
    state.selectedTagsIds = [];
  });
  builder.addCase(setExpandedIngredient, (state, { payload }) => {
    state.expandedIngredient = payload;
  });
  builder.addCase(setSelectedSubRecipeTag, (state, { payload }) => {
    if (state.selectedSubRecTagsIds.includes(payload)) {
      remove(state.selectedSubRecTagsIds, (el) => el === payload);
    } else {
      state.selectedSubRecTagsIds.push(payload);
    }
  });
  builder.addCase(clearSelectedSubRecipeTags, (state) => {
    state.selectedSubRecTagsIds = [];
  });
  builder.addCase(setSelectAlternativeMode, (state, { payload }) => {
    state.selectAlternativeModeFor = payload;
  });
  builder.addCase(setReplaceMode, (state, { payload }) => {
    state.replaceModeData = payload;
  });
  builder.addCase(setCreateAlternativeMode, (state, { payload }) => {
    state.createAlternativeData = payload;
  });
  builder.addCase(setAlternativeId, (state, { payload }) => {
    state.alternativeId = payload;
  });
  builder.addCase(setSelectedArchivedRecipeTag, (state, { payload }) => {
    if (state.selectedArchivedRecTagsIds.includes(payload)) {
      remove(state.selectedArchivedRecTagsIds, (el) => el === payload);
    } else {
      state.selectedArchivedRecTagsIds.push(payload);
    }
  });
  builder.addCase(clearSelectedArchivedRecipeTags, (state) => {
    state.selectedArchivedRecTagsIds = [];
  });
  builder.addCase(setSearchByTagState, (state, { payload }) => {
    if (!isEqual(state.searchByTagState, payload)) {
      state.selectedTagsIds = [];
      state.selectedSubRecTagsIds = [];
      state.selectedArchivedRecTagsIds = [];
    }
    state.searchByTagState = payload;
  });
  builder.addMatcher(menuApi.endpoints.multiplyRecipe.matchPending, (state) => {
    state.quantityRounded = false;
  });
  builder.addMatcher(menuApi.endpoints.addAlternative.matchFulfilled, (state) => {
    state.alternativeId = null;
    state.createAlternativeData = null;
  });
});
