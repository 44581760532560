import remove from 'lodash/remove';
import { TokenService } from '../../shared/services/token.service';
import { addNewIngredientId, removeNewIngredientId } from '../../store/menu/menu.actions';
import { setAppSuccessToast } from '../../store/user';
import { getRecipesQueryParamsHelper } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  AddAllToCartReq,
  AddAmountAndMeasureReq,
  AddIngredientsRequest,
  AddSubRecipeRequest,
  CreateMenuRequest,
  CreateRecipeRequest,
  CreateRecipeResponse,
  DuplicateRecipeRequest,
  DuplicateRecipeResponse,
  ExportIngredientsResponse,
  ExportRecipeCardRes,
  GetMenusResponse,
  GetRecipesRequest,
  GetRecipesResponse,
  Menu,
  MultiplyRecipeResponse,
  Recipe,
  RecipeIngredient,
  ReplaceProductReq,
  UpdateRecipeReq,
  UpdateRecipeReqJSON,
  UpdateRecipeRes,
} from './types';

export const menuApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMenus: builder.query<GetMenusResponse, boolean | undefined>({
      query: (sub_recipe_category) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/menus`,
          method: HTTP.GET,
          params: { sub_recipe_category },
        };
      },
      providesTags: ['Menu'],
    }),

    addMenu: builder.mutation<Menu, CreateMenuRequest>({
      query: (body) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/menus`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Menu'],
    }),

    deleteMenu: builder.mutation<SuccessResponse, { id: number; sub?: boolean }>({
      query: ({ id }) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/menus/${id}/delete_menu`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let deletedCategoryName;
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getMenus', arg.sub, (draft) => {
            const idx = draft.menus.findIndex((el) => el.id === arg.id);
            if (idx !== -1) {
              deletedCategoryName = draft.menus[idx].name;
              draft.menus[idx].deleted_at = new Date().toString();
            } else {
              return draft;
            }
          }),
        );
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast(`"${deletedCategoryName}" category has been deleted`));
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Menu'],
    }),

    archiveMenu: builder.mutation<SuccessResponse, { id: number; sub?: boolean }>({
      query: ({ id }) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/menus/${id}/archive`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let deletedCategoryName;
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getMenus', arg.sub, (draft) => {
            const idx = draft.menus.findIndex((el) => el.id === arg.id);
            if (idx !== -1) {
              deletedCategoryName = draft.menus[idx].name;
              draft.menus[idx].archived_at = new Date().toString();
            } else {
              return draft;
            }
          }),
        );
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast(`"${deletedCategoryName}" category has been archived`));
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Menu', 'Recipe', 'Recipes'],
    }),

    renameMenu: builder.mutation<SuccessResponse, { name: string; id: number; sub?: boolean }>({
      query: ({ id, sub, ...body }) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/menus/${id}/rename_menu`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getMenus', arg.sub, (draft) => {
            const idx = draft.menus.findIndex((el) => el.id === arg.id);
            if (idx !== -1) {
              draft.menus[idx].name = arg.name;
            } else {
              return draft;
            }
          }),
        );
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast(`Category name has been updated`));
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Menu'],
    }),

    addRecipe: builder.mutation<CreateRecipeResponse, CreateRecipeRequest | { formData: FormData }>({
      query: (recipe) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies`,
          method: HTTP.POST,
          body:
            'formData' in recipe
              ? recipe.formData
              : {
                  recipe,
                },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          if (!data?.recipe) {
            return;
          }
          patchResult = dispatch(
            menuApi.util.updateQueryData('getRecipeById', data.recipe.id, () => {
              return data.recipe;
            }),
          );
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: ['Menu', 'Recipe', 'Recipes'],
    }),

    deleteRecipeFromMenu: builder.mutation<SuccessResponse, { recipeId: number; sub?: boolean }>({
      query: ({ recipeId }) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/${recipeId}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let recipeName;
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getMenus', arg.sub, (draft) => {
            const idx = draft.menus.findIndex((el) => el.recipes.some((recipe) => recipe.id === arg.recipeId));
            if (idx !== -1) {
              const recipeIdx = draft.menus[idx].recipes.findIndex((recipe) => recipe.id === arg.recipeId);
              recipeName = draft.menus[idx].recipes[recipeIdx]?.name;
              draft.menus[idx].recipes.splice(recipeIdx, 1);
            } else {
              return draft;
            }
          }),
        );
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast(`${recipeName} recipe has been deleted`));
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Menu', 'Recipes', 'Recipe'],
    }),

    getRecipeById: builder.query<Recipe, number>({
      query: (recipeId) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/${recipeId}`,
          method: HTTP.GET,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          if (data) {
            patchResult = dispatch(
              menuApi.util.updateQueryData('getRecipeById', arg, (draft) => {
                draft.ingredients = data.ingredients.map((el) => ({
                  ...el,
                  batchedQuantity: el.quantity ? (data.batch_size || 1) / el.quantity : 0,
                  cartQuantity: el.quantity ? (data.batch_size || 1) / el.quantity : 0,
                }));
              }),
            );
          }
        } catch {
          patchResult?.undo();
        }
      },
      providesTags: ['Recipe'],
    }),

    getRecipes: builder.query<GetRecipesResponse, GetRecipesRequest>({
      query: (params) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/`,
          method: HTTP.GET,
          params: getRecipesQueryParamsHelper(params),
        };
      },
      providesTags: ['Recipes'],
    }),

    getAllRecipes: builder.mutation<GetRecipesResponse, void>({
      query: (params) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/`,
          method: HTTP.GET,
          params: { most_sales: true },
        };
      },
    }),

    searchRecipes: builder.query<GetRecipesResponse, GetRecipesRequest>({
      query: (params) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/`,
          method: HTTP.GET,
          params: getRecipesQueryParamsHelper(params),
        };
      },
      providesTags: ['Recipes'],
    }),

    updateRecipe: builder.mutation<UpdateRecipeRes, UpdateRecipeReq | UpdateRecipeReqJSON>({
      query: ({ id, ...recipe }) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/${id}`,
          method: HTTP.PATCH,
          body: 'formData' in recipe ? recipe.formData : { recipe },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          if (data?.recipe) {
            patchResult = dispatch(
              menuApi.util.updateQueryData('getRecipeById', arg.id, () => {
                return {
                  ...data.recipe,
                  ingredients: data.recipe.ingredients.map((el) => ({
                    ...el,
                    batchedQuantity: el.quantity ? data.recipe.batch_size / el.quantity : 0,
                    cartQuantity: el.quantity ? data.recipe.batch_size / el.quantity : 0,
                  })),
                };
              }),
            );
          }
        } catch {
          patchResult?.undo();
        }
      },

      invalidatesTags: ['Menu', 'Recipes'],
    }),

    archiveRecipe: builder.mutation<SuccessResponse, { recipeId: number; sub?: boolean }>({
      query: ({ recipeId }) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/${recipeId}/archive`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let recipeName;
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getMenus', arg.sub, (draft) => {
            const idx = draft.menus.findIndex((el) => el.recipes.some((recipe) => recipe.id === arg.recipeId));
            if (idx !== -1) {
              const recipeIdx = draft.menus[idx].recipes.findIndex((recipe) => recipe.id === arg.recipeId);
              recipeName = draft.menus[idx].recipes[recipeIdx]?.name;
              draft.menus[idx].recipes[recipeIdx].archived_at = new Date().toString();
            } else {
              return draft;
            }
          }),
        );
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast(`${recipeName} recipe has been archived`));
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Menu', 'Recipe', 'Recipes'],
    }),

    restoreRecipe: builder.mutation<SuccessResponse, number>({
      query: (recipeId) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/${recipeId}/unarchive`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let recipeName;
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getRecipes', {}, (draft) => {
            const idx = draft.recipies.findIndex((el) => el.id === arg);
            if (idx !== -1) {
              recipeName = draft.recipies[idx].name;
              draft.recipies[idx].archived_at = null;
            } else {
              return draft;
            }
          }),
        );
        const patchResult1 = dispatch(
          menuApi.util.updateQueryData('getRecipes', { sub_recipe: true }, (draft) => {
            const idx = draft.recipies.findIndex((el) => el.id === arg);
            if (idx !== -1) {
              recipeName = draft.recipies[idx].name;
              draft.recipies[idx].archived_at = null;
            } else {
              return draft;
            }
          }),
        );
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast(`${recipeName} recipe has been restored`));
        } catch {
          patchResult.undo();
          patchResult1.undo();
        }
      },
      invalidatesTags: ['Menu', 'Recipe', 'Recipes'],
    }),

    deleteRecipe: builder.mutation<SuccessResponse, number>({
      query: (recipeId) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/${recipeId}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        let recipeName;
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getRecipes', {}, (draft) => {
            const idx = draft.recipies.findIndex((el) => el.id === arg);
            if (idx !== -1) {
              recipeName = draft.recipies[idx].name;
              draft.recipies[idx].archived_at = null;
            } else {
              return draft;
            }
          }),
        );
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast(`${recipeName} recipe has been deleted`));
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Menu', 'Recipes'],
    }),

    updateMenuPosition: builder.mutation<
      SuccessResponse,
      {
        body: { menu_data: { [key: string]: number } };
        sub?: boolean;
      }
    >({
      query: ({ body }) => {
        return {
          url: `/save_menu_postion`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getMenus', arg.sub, (draft) => {
            draft.menus = draft.menus.map((menu) => ({
              ...menu,
              position: arg.body.menu_data[menu.id.toString()],
            }));
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    updateRecipePosition: builder.mutation<
      SuccessResponse,
      {
        body: { recipe_data: { [key: string]: number } };
        sub?: boolean;
      }
    >({
      query: ({ body }) => {
        return {
          url: `/save_recipe_postion`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getMenus', arg.sub, (draft) => {
            draft.menus = draft.menus.map((menu) => ({
              ...menu,
              recipes: menu.recipes.map((rec) => ({
                ...rec,
                position: arg.body.recipe_data[rec.id.toString()] || rec.position,
              })),
            }));
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    updateRecipeCategory: builder.mutation<SuccessResponse, { id: number; menu_id: number; sub?: boolean }>({
      query: ({ id, sub, ...recipe }) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/${id}`,
          method: HTTP.PATCH,
          body: { recipe },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getMenus', arg.sub, (draft) => {
            const menuIdxToDeleteRecipe = draft.menus.findIndex((menu) => menu.recipes.some((rec) => rec.id === arg.id));
            const menuIdxToAddRecipe = draft.menus.findIndex((menu) => menu.id === arg.menu_id);
            const movedRecipe = draft.menus[menuIdxToDeleteRecipe].recipes.find((el) => el.id === arg.id);
            if (menuIdxToDeleteRecipe !== -1) {
              draft.menus[menuIdxToDeleteRecipe].recipes = draft.menus[menuIdxToDeleteRecipe].recipes.filter((el) => el.id !== arg.id);
            }
            if (menuIdxToAddRecipe !== -1 && movedRecipe) {
              draft.menus[menuIdxToAddRecipe].recipes.push(movedRecipe);
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Recipe'],
    }),

    addIngredients: builder.mutation<SuccessResponse, AddIngredientsRequest>({
      query: (body) => {
        return {
          url: `/ingredients/add_product`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getRecipeById', arg.recipe_id, (draft) => {
            draft.ingredients.push({
              product_id: arg.product_id,
              batchedQuantity: 1,
              cartQuantity: 1,
            } as RecipeIngredient);
          }),
        );
        dispatch(addNewIngredientId({ id: arg.product_id, type: 'product' }));
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),

    addAmountAndMeasure: builder.mutation<SuccessResponse, AddAmountAndMeasureReq>({
      query: ({ recipe_id, ingredient_id, ...ingredient }) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/recipies/${recipe_id}/add_amount_and_measure`,
          method: HTTP.PATCH,
          body: {
            ingredient_id,
            ingredient,
          },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getRecipeById', arg.recipe_id, (draft) => {
            const ingredientIdx = draft.ingredients.findIndex((el) => el.id === arg.ingredient_id);
            if (ingredientIdx !== -1) {
              draft.ingredients[ingredientIdx].quantity = arg.quantity ? +arg.quantity : null;
              draft.ingredients[ingredientIdx].measure = arg.measure || null;
              draft.ingredients[ingredientIdx].unit_of_measure = arg.unit_of_measure || null;
            }
            return draft;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: ['Recipe', 'Menu', 'Recipes'],
    }),

    removeIngredient: builder.mutation<SuccessResponse, AddIngredientsRequest>({
      query: ({ invalidate, ...body }) => {
        return {
          url: `/ingredients/remove_product`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getRecipeById', arg.recipe_id, (draft) => {
            remove(draft.ingredients, (el) => el.product_id === arg.product_id);
          }),
        );
        try {
          await queryFulfilled;
          dispatch(removeNewIngredientId({ id: arg.product_id, type: 'product' }));
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: (res, data, arg) => (arg.invalidate ? ['Recipe', 'Menu', 'Recipes'] : []),
    }),

    saveIngredients: builder.mutation<SuccessResponse, number>({
      query: (recipe_id) => {
        return {
          url: `/ingredients/save_ingredients`,
          method: HTTP.POST,
          body: {
            recipe_id,
          },
        };
      },
      invalidatesTags: ['Recipe', 'Menu'],
    }),

    duplicateRecipe: builder.mutation<DuplicateRecipeResponse, DuplicateRecipeRequest>({
      query: ({ recipe_id, position, ...body }) => {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/recipies/${recipe_id}/duplicate_recipe`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const updatedPositions: any = {};
        let patchResult;
        try {
          const {
            data: { recipe },
          } = await queryFulfilled;
          patchResult = dispatch(
            menuApi.util.updateQueryData('getMenus', recipe.is_sub_recipe || undefined, (draft) => {
              const idx = draft.menus.findIndex((el) => el.recipes.some((rec) => rec.id === arg.recipe_id));
              if (idx !== -1) {
                draft.menus[idx].recipes.forEach((res) => {
                  if (arg.position && res.position && res.position > arg.position) {
                    updatedPositions[res.id.toString()] = res.position + 1;
                    res.position = arg.position + 1;
                  }
                });
                draft.menus[idx].recipes.push({
                  id: recipe.id,
                  name: recipe.name,
                  archived_at: recipe.archived_at,
                  description: recipe.description,
                  batch_recipe: recipe.batch_recipe,
                  batch_size: recipe.batch_size,
                  cook_time: recipe.cook_time,
                  picture_url: recipe.picture_url,
                  prep_time: recipe.prep_time,
                  price_cents: recipe.price_cents,
                  gross_margin_percentage: recipe.gross_margin_percentage,
                  food_cost_percentage: recipe.food_cost_percentage,
                  position: arg.position + 1,
                  is_sub_recipe: recipe.is_sub_recipe,
                });
              } else {
                return draft;
              }
            }),
          );
          updatedPositions[recipe.id.toString()] = arg.position + 1;
          await dispatch(menuApi.endpoints.updateRecipePosition.initiate({ body: { recipe_data: updatedPositions } }));
          dispatch(rootApi.util.invalidateTags(['Menu']));
        } catch {
          patchResult?.undo();
        }
      },
    }),

    exportRecipeCard: builder.query<ExportRecipeCardRes, number>({
      query: (id) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/recipies/${id}/export_recipe`,
          method: HTTP.GET,
        };
      },
      keepUnusedDataFor: 0,
    }),

    multiplyRecipe: builder.mutation<MultiplyRecipeResponse, { id: number; size: number }>({
      query: ({ id, ...body }) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/recipies/${id}/multiply_recipe`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getRecipeById', arg.id, (draft) => {
            draft.ingredients = draft.ingredients.map((el) => ({
              ...el,
              batchedQuantity: el.quantity ? arg.size / el.quantity : 0,
              cartQuantity: el.quantity ? arg.size / el.quantity : 0,
            }));
            draft.batch_size = arg.size;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),

    exportIngredients: builder.query<ExportIngredientsResponse, { id: number; round_up_quantity?: boolean }>({
      query: ({ id, ...params }) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/recipies/${id}/export_ingredients`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    exportIngredientsPDF: builder.query<any, { id: number; round_up_quantity?: boolean }>({
      query: ({ id, round_up_quantity }) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/recipies/${id}/export_ingredients`,
          method: HTTP.GET,
          params: {
            export_pdf: true,
            round_up_quantity,
          },
        };
      },
      keepUnusedDataFor: 0,
    }),

    addAllToCart: builder.mutation<SuccessResponse, AddAllToCartReq>({
      query: (body) => {
        return {
          url: `/add_to_cart`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Cart'],
    }),

    addSubRecipe: builder.mutation<SuccessResponse, AddSubRecipeRequest>({
      query: ({ recipe_id, id }) => {
        return {
          url: `/recipes/${recipe_id}/sub_recipes/${id}/add_to_recipe`,
          method: HTTP.POST,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getRecipeById', arg.recipe_id, (draft) => {
            draft.ingredients.push({ sub_recipe_id: arg.id } as RecipeIngredient);
          }),
        );
        dispatch(addNewIngredientId({ id: arg.id, type: 'recipe' }));
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),

    removeSubRecipe: builder.mutation<SuccessResponse, AddSubRecipeRequest>({
      query: ({ recipe_id, id }) => {
        return {
          url: `/recipes/${recipe_id}/sub_recipes/${id}/remove_from_recipe`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApi.util.updateQueryData('getRecipeById', arg.recipe_id, (draft) => {
            remove(draft.sub_recipes, (el) => el.id === arg.id);
            remove(draft.ingredients, (el) => el.sub_recipe_id === arg.id);
          }),
        );
        try {
          await queryFulfilled;
          dispatch(removeNewIngredientId({ id: arg.id, type: 'recipe' }));
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: (res, data, arg) => (arg.invalidate ? ['Recipe', 'Menu', 'Recipes'] : []),
    }),

    replaceProduct: builder.mutation<SuccessResponse, ReplaceProductReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/ingredients/${id}/replace_product`,
          method: HTTP.PATCH,
          body,
        };
      },
    }),

    addAlternative: builder.mutation<SuccessResponse, ReplaceProductReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/ingredients/${id}/add_alternate`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Recipe', 'Menu', 'Recipes'],
    }),

    toggleDefault: builder.mutation<SuccessResponse, { id: number; recipe_id: number }>({
      query: ({ id, ...body }) => {
        return {
          url: `/ingredients/${id}/change_default`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Recipe', 'Menu', 'Recipes'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetMenusQuery,
  useAddMenuMutation,
  useDeleteMenuMutation,
  useAddRecipeMutation,
  useGetRecipeByIdQuery,
  useUpdateRecipeMutation,
  useUpdateMenuPositionMutation,
  useUpdateRecipePositionMutation,
  useUpdateRecipeCategoryMutation,
  useRenameMenuMutation,
  useAddIngredientsMutation,
  useRemoveIngredientMutation,
  useSaveIngredientsMutation,
  useAddAmountAndMeasureMutation,
  useDeleteRecipeFromMenuMutation,
  useDuplicateRecipeMutation,
  useLazyExportRecipeCardQuery,
  useGetRecipesQuery,
  useLazyGetRecipesQuery,
  useArchiveRecipeMutation,
  useRestoreRecipeMutation,
  useDeleteRecipeMutation,
  useLazySearchRecipesQuery,
  useMultiplyRecipeMutation,
  useLazyExportIngredientsQuery,
  useLazyExportIngredientsPDFQuery,
  useAddAllToCartMutation,
  useAddSubRecipeMutation,
  useRemoveSubRecipeMutation,
  useGetAllRecipesMutation,
  usePrefetch,
  useReplaceProductMutation,
  useAddAlternativeMutation,
  useToggleDefaultMutation,
} = menuApi;
