import { createReducer } from '@reduxjs/toolkit';

import { CostPlan } from '../../api/admin-countries/types';
import { userApi } from '../../api/user';
import { Roles, SubscriptionPlanEnum } from '../../api/user/types';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import {
  addCartPendingRequest,
  closeAccountMenu,
  closeMainMenu,
  impersonateCustomer,
  logoutThunk,
  openAccountMenu,
  openMainMenu,
  openUseDesktopForTools,
  removeCartPendingRequest,
  setAdminCountryCode,
  setAppErrorToast,
  setAppSuccessToast,
  setCartDeliveryLoading,
  setCostPlans,
  setFaqKeyword,
  setHelpCenterKeyword,
  setHelpKeyword,
  setImpersonated,
  setLearningMode,
  setSameProductPopup,
  setSuccessMsg,
} from './user.actions';

interface State {
  userId: number | null;
  role: Roles | null;
  isAdmin: boolean;
  isSa: boolean;
  successMsg: string | null;
  learningMode: boolean;
  isMainMenuOpened: boolean;
  isAccountMenuOpened: boolean;
  createdAt?: string;
  cartPendingRequests: string[] | null;
  subscriptionType?: SubscriptionPlanEnum;
  errorToastText: string | null;
  successToastText: string | null;
  toastTitle: string | null;
  faqSearch: string;
  helpSearch: string;
  helpCenterSearch: string;
  impersonated: boolean;
  useDesktopForToolsOpened: boolean;
  impersonatedCustomer: { customer_id: number; connection_id: number } | null;
  adminCountryCode: string | null;
  cartDeliveryLoading: boolean;
  plans: CostPlan[];
  sameProductPopup: boolean;
}

const initialState: State = {
  userId: null,
  isAdmin: false,
  isSa: false,
  role: !!LocalStorageService.getItem('impersonated_customer') ? Roles.CUSTOMER : null,
  successMsg: null,
  learningMode: false,
  isMainMenuOpened: false,
  isAccountMenuOpened: false,
  useDesktopForToolsOpened: false,
  createdAt: undefined,
  errorToastText: null,
  successToastText: null,
  toastTitle: null,
  subscriptionType: undefined,
  cartPendingRequests: null,
  faqSearch: '',
  helpSearch: '',
  helpCenterSearch: '',
  impersonated: !!LocalStorageService.getItem('a_token'),
  impersonatedCustomer: LocalStorageService.getItem('impersonated_customer'),
  adminCountryCode: null,
  cartDeliveryLoading: false,
  plans: [],
  sameProductPopup: true,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return { ...initialState, impersonatedCustomer: null };
  });
  builder.addCase(setSuccessMsg, (state, { payload }) => {
    state.successMsg = payload.message;
  });
  builder.addCase(setLearningMode, (state, { payload }) => {
    state.learningMode = payload;
  });
  builder.addCase(openMainMenu, (state) => {
    state.isMainMenuOpened = true;
  });
  builder.addCase(closeMainMenu, (state) => {
    state.isMainMenuOpened = false;
  });
  builder.addCase(openAccountMenu, (state) => {
    state.isAccountMenuOpened = true;
  });
  builder.addCase(closeAccountMenu, (state) => {
    state.isAccountMenuOpened = false;
  });
  builder.addCase(openUseDesktopForTools, (state, { payload }) => {
    state.useDesktopForToolsOpened = payload;
  });
  builder.addCase(setAppErrorToast, (state, { payload }) => {
    state.errorToastText = payload;
  });
  builder.addCase(setAppSuccessToast, (state, { payload }) => {
    if(typeof payload === 'string') {
      state.successToastText = payload;
    } else if (typeof payload === 'object') {
      if(payload === null) {
        state.toastTitle = null;
        state.successToastText = null;
      } else {
        state.toastTitle = payload?.title;
        state.successToastText = payload?.message;
      }
    }
  });
  builder.addCase(addCartPendingRequest, (state, { payload }) => {
    if (Array.isArray(state.cartPendingRequests)) {
      state.cartPendingRequests.push(payload);
    } else {
      state.cartPendingRequests = [payload];
    }
  });
  builder.addCase(removeCartPendingRequest, (state, { payload }) => {
    if (Array.isArray(state.cartPendingRequests) && state.cartPendingRequests.length > 1) {
      state.cartPendingRequests = state.cartPendingRequests.filter((req) => req !== payload);
    } else {
      state.cartPendingRequests = null;
    }
  });
  builder.addCase(setFaqKeyword, (state, { payload }) => {
    state.faqSearch = payload;
  });
  builder.addCase(setHelpCenterKeyword, (state, { payload }) => {
    state.helpCenterSearch = payload;
  });
  builder.addCase(setHelpKeyword, (state, { payload }) => {
    state.helpSearch = payload;
  });
  builder.addCase(setImpersonated, (state, { payload }) => {
    state.impersonated = payload;
  });
  builder.addCase(impersonateCustomer, (state, { payload }) => {
    state.role = payload ? Roles.CUSTOMER : Roles.SUPPLIER;
    state.impersonatedCustomer = payload;
  });
  builder.addCase(setAdminCountryCode, (state, { payload }) => {
    state.adminCountryCode = payload;
  });
  builder.addCase(setCartDeliveryLoading, (state, { payload }) => {
    state.cartDeliveryLoading = payload;
  });
  builder.addCase(setCostPlans, (state, { payload }) => {
    state.plans = payload;
  });
  builder.addCase(setSameProductPopup, (state, { payload }) => {
    state.sameProductPopup = payload;
  });
  builder.addMatcher(userApi.endpoints.me.matchFulfilled, (state, { payload }) => {
    state.userId = payload.id;
    state.role = !!LocalStorageService.getItem('impersonated_customer') ? Roles.CUSTOMER : payload.company?.company_type || null;
    state.createdAt = payload.created_at;
    state.subscriptionType = payload.subscription_type;
    state.isAdmin = payload.is_admin;
    state.isSa = payload.is_sa;
  });
});
