import React from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';

import { useStyles } from './style';
import { useAppSelector } from '../../../store';
import { getDemoColors } from '../../../store/admin/themes';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';

interface Props extends ButtonProps {
  buttonStyle?: 'primary' | 'primaryBordered' | 'secondary' | 'icon' | 'blueButton' | 'blueTransparent' | 'white' | 'greenTransparent' | 'alert';
  isSmall?: boolean;
  isSmaller?: boolean;
  width?: number | 'auto';
  bordered?: boolean;
  demoMode?: boolean;
  customClass?: string;
}

export const ThemedButton: React.FC<Props> = React.memo(
  ({ title, isSmall, isSmaller, width, buttonStyle = 'primary', bordered = false, demoMode, customClass, ...btnProps }) => {
    const demoColors = useAppSelector(getDemoColors);
    const { colors } = useCurrentTheme();

    const textColor = demoMode ? demoColors[`${buttonStyle}_btnText`] : colors[`${buttonStyle}_btnText`];
    const bgColor = demoMode ? demoColors[`${buttonStyle}_btnBg`] : colors[`${buttonStyle}_btnBg`];

    const classes = useStyles({ width, bordered, textColor, bgColor });
    return (
      <Button
        classes={{
          root: clsx([classes.root, classes[buttonStyle], isSmall && classes.smallButton, isSmaller && classes.smallerButton]),
        }}
        {...btnProps}
        className={clsx(customClass, btnProps.className)}
      >
        {title}
      </Button>
    );
  },
);
