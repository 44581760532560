import { createReducer } from '@reduxjs/toolkit';
import { logoutThunk } from '../user';
import {
  setStocktakeMode,
  setStocktakeBarcode,
  setStocktakeProducts,
  setStockCount,
  setProductSettings,
  setStocktakeSettings,
  setStocktakeAppliedProduct,
} from './products-stocktake.actions';
import { Inventory, StocktakeMode } from '../../api/inventory/types';

interface State {
  mode?: StocktakeMode;
  barcode: string;
  products: Inventory[];
  product?: Inventory;
  onlyOneProduct: boolean;
  stockCount: number;
  supplierId?: number;
  isDefault: boolean;
  isTransfer: boolean;
  useSameOption: boolean;
  hasDefaultProduct: boolean;
  found_barcode: string;
}

const initialState: State = {
  mode: undefined,
  barcode: '',
  products: [],
  product: undefined,
  onlyOneProduct: false,
  stockCount: 0,
  supplierId: undefined,
  isDefault: false,
  isTransfer: false,
  useSameOption: false,
  hasDefaultProduct: false,
  found_barcode: '',
};

export const stocktakeBarcodeReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setStocktakeBarcode, (state, { payload }) => {
    if (state.barcode === payload) {
      return;
    }
    state.found_barcode = state.barcode
    state.barcode = payload;
  });
  builder.addCase(setStocktakeMode, (state, { payload }) => {
    if (state.mode === payload) {
      return;
    }
    state.mode = payload as StocktakeMode;
  });
  builder.addCase(setStocktakeProducts, (state, { payload }) => {
    state.products = payload;
    state.onlyOneProduct = payload.length < 2;
    if (payload.length === 1) {
      state.product = payload[0];
    }
    if (payload.length > 1) {
      const defaultInventoryProduct = payload.find((p) => p.is_default);
      state.hasDefaultProduct = defaultInventoryProduct ? true : false;
      state.product = defaultInventoryProduct ? defaultInventoryProduct : payload[0];
    }
  });
  builder.addCase(setStocktakeAppliedProduct, (state, { payload }) => {
    const product = state.products.find((p) => p.id === payload);
    if (product && product?.id !== state.product?.id) {
      state.product = product;
    }
  });
  builder.addCase(setStockCount, (state, { payload }) => {
    state.stockCount = payload;
  });
  builder.addCase(setProductSettings, (state, { payload }) => {
    state.supplierId = payload.supplierId;
    state.isDefault = payload.isDefault;
    state.isTransfer = payload.isTransfer;
  });
  builder.addCase(setStocktakeSettings, (state, { payload }) => {
    state.useSameOption = payload;
  });
});
