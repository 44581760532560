import { parseFloatFromString } from '../../shared/helpers/parseFloatFromString';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { productsAdapter } from '../../store/products';
import rootReducer from '../../store/rootReducer';
import { addCartPendingRequest, removeCartPendingRequest, setCartDeliveryLoading } from '../../store/user';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { prepListApi } from '../prep-list';
import { BaseUser, SupplierUser } from '../user/types';
import {
  AddToCartRequest,
  CartDeliveryFeeResponse,
  CommonCartResponse,
  GetCartResponse,
  PlaceBySupplierCartReq,
  RemoveFromCartRequest,
  UpdateCartCommentRequest,
  UpdateQuantityRequest,
} from './types';

export const cartApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCart: builder.query<GetCartResponse, undefined>({
      query: () => {
        const params: PlaceBySupplierCartReq = {};
        if (LocalStorageService.getItem('impersonated_customer')) {
          params.place_by_supplier = true;
          params.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/carts`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Cart'],
      keepUnusedDataFor: 0,
    }),

    getCartForQuery: builder.query<GetCartResponse, undefined>({
      query: () => {
        const params: PlaceBySupplierCartReq = {};
        if (LocalStorageService.getItem('impersonated_customer')) {
          params.place_by_supplier = true;
          params.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/carts`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getCartDeliveryFee: builder.query<CartDeliveryFeeResponse, { date?: string }>({
      query: ({ date }) => {
        const params: PlaceBySupplierCartReq & { date?: string } = { date };
        if (LocalStorageService.getItem('impersonated_customer')) {
          params.place_by_supplier = true;
          params.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/carts/cart_delivery_date`,
          method: HTTP.GET,
          params,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setCartDeliveryLoading(true));
          await queryFulfilled;
          dispatch(cartApi.endpoints.getCart.initiate(undefined, { forceRefetch: true }));
        } catch (e) {
          console.log(e);
        }
        dispatch(setCartDeliveryLoading(false));
      },
      providesTags: ['Cart', 'Cart_Delivery'],
      keepUnusedDataFor: 0,
    }),

    addToCart: builder.mutation<SuccessResponse, AddToCartRequest>({
      query: ({ invalidate, ...args }) => {
        const body = { ...args };
        if (LocalStorageService.getItem('impersonated_customer')) {
          body.place_by_supplier = true;
          body.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/carts/add_product_to_cart`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        const patchResult = dispatch(
          cartApi.util.updateQueryData('getCart', undefined, (draft) => {
            const productToCart = productsAdapter
              .getSelectors()
              .selectById((getState() as ReturnType<typeof rootReducer>).products.list, args.product_id);
            const cartIndexBySupplier = draft.carts.findIndex((cart) => cart.supplier.id === productToCart?.supplier.id);
            const cartIndexByProduct = draft.carts.findIndex((cart) => cart.products.some((prodItem) => prodItem.id === args.product_id));

            if (cartIndexBySupplier === -1 && productToCart) {
              const cartId = Date.now();
              draft.carts.push({
                id: cartId,
                comment: null,
                cutoff_time: null,
                minimum_order_value_message: false,
                cutoff_time_message: false,
                total_price: productToCart.product_price * (parseFloatFromString(productToCart.unit_size) || 1),
                available_days: (productToCart.supplier as SupplierUser).delivery_detail,
                supplier: productToCart.supplier as SupplierUser,
                products: [productToCart],
                cart_products: [
                  {
                    id: Date.now(),
                    quantity: parseFloatFromString(productToCart.unit_size) || 1,
                    product_id: productToCart.id,
                    cart_id: cartId,
                    total_price: productToCart.product_price * (parseFloatFromString(productToCart.unit_size) || 1),
                  },
                ],
                next_available_delivery_date: new Date().toDateString(),
                user: {} as BaseUser & Pick<SupplierUser, 'company_addresses'>,
              });
            } else if (cartIndexByProduct !== -1) {
              const cartProdIdx = draft.carts[cartIndexByProduct].cart_products.findIndex((el) => el.product_id === args.product_id);
              const currentProduct = draft.carts[cartIndexByProduct].cart_products[cartProdIdx];
              const productPrice = currentProduct ? currentProduct.total_price / currentProduct.quantity : 0;
              draft.carts[cartIndexByProduct].cart_products[cartProdIdx].total_price += productPrice;
              draft.carts[cartIndexByProduct].total_price += productPrice;
              draft.carts[cartIndexByProduct].cart_products[cartProdIdx].quantity++;
            } else if (cartIndexBySupplier !== -1 && cartIndexByProduct === -1 && productToCart) {
              draft.carts[cartIndexBySupplier].cart_products.push({
                id: Date.now(),
                quantity: parseFloatFromString(productToCart.unit_size) || 1,
                product_id: productToCart.id,
                cart_id: draft.carts[cartIndexBySupplier].id,
                total_price: productToCart.product_price * (parseFloatFromString(productToCart.unit_size) || 1),
              });
              draft.carts[cartIndexBySupplier].products.push(productToCart);
              draft.carts[cartIndexBySupplier].total_price +=
                productToCart.product_price * (parseFloatFromString(productToCart.unit_size) || 1);
            }
          }),
        );
        try {
          const {
            data: { success },
          } = await queryFulfilled;
          if (!success) {
            patchResult?.undo();
            dispatch(rootApi.util.invalidateTags(['Cart']));
          }
        } catch {
          try {
            patchResult?.undo();
            dispatch(rootApi.util.invalidateTags(['Cart']));
          } catch (e) {
            console.log(e);
          }
        }
      },
      invalidatesTags: (res, _, arg) => (arg.invalidate ? ['Cart'] : ['Cart_Delivery']),
    }),

    updateQuantityInCart: builder.mutation<SuccessResponse, UpdateQuantityRequest>({
      query: ({ invalidate, ...args }) => {
        const body = { ...args };
        if (LocalStorageService.getItem('impersonated_customer')) {
          body.place_by_supplier = true;
          body.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/carts/update_quantity_in_cart`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          cartApi.util.updateQueryData('getCart', undefined, (draft) => {
            const cartIndexByProduct = draft.carts.findIndex((cart) => cart.products.some((prodItem) => prodItem.id === args.product_id));
            const cartProdIdx = draft.carts[cartIndexByProduct]?.cart_products?.findIndex((el) => el.product_id === args.product_id);
            if (cartProdIdx === undefined) {
              return draft;
            }
            const currentProduct = draft.carts[cartIndexByProduct].cart_products[cartProdIdx];
            const productPrice = currentProduct.total_price / currentProduct.quantity;

            if (args.quantity > 0) {
              draft.carts[cartIndexByProduct].total_price += productPrice * args.quantity - currentProduct.total_price;
              draft.carts[cartIndexByProduct].cart_products[cartProdIdx].total_price = productPrice * args.quantity;
              draft.carts[cartIndexByProduct].cart_products[cartProdIdx].quantity = args.quantity;
            } else if (args.quantity === 0 && draft.carts[cartIndexByProduct].cart_products.length === 1) {
              draft.carts.splice(cartIndexByProduct, 1);
            } else if (args.quantity === 0 && draft.carts[cartIndexByProduct].cart_products.length > 1) {
              const prodIdx = draft.carts[cartIndexByProduct].products.findIndex((el) => el.id === args.product_id);
              draft.carts[cartIndexByProduct].cart_products.splice(cartProdIdx, 1);
              draft.carts[cartIndexByProduct].products.splice(prodIdx, 1);
              draft.carts[cartIndexByProduct].total_price -= currentProduct.total_price;
            }
          }),
        );
        try {
          const {
            data: { success },
          } = await queryFulfilled;
          if (!success) {
            patchResult.undo();
            dispatch(rootApi.util.invalidateTags(['Cart']));
          }
        } catch {
          patchResult.undo();
          dispatch(rootApi.util.invalidateTags(['Cart']));
        }
      },
      invalidatesTags: (res, _, args) => (args.invalidate === false ? [] : ['Cart_Delivery']),
    }),

    updateCartComment: builder.mutation<CommonCartResponse, UpdateCartCommentRequest>({
      query: ({ cart_id, ...body }) => {
        return {
          url: `/carts/${cart_id}/update_comment`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Cart'],
    }),

    emptyCart: builder.mutation<SuccessResponse, void>({
      query: () => {
        return {
          url: `/carts/empty_cart`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const patchCartResult = dispatch(
          cartApi.util.updateQueryData('getCart', undefined, (draft) => {
            draft.carts = [];
          }),
        );
        try {
          await queryFulfilled;
          dispatch(prepListApi.util.invalidateTags(['Prep_List']));
        } catch {
          patchCartResult.undo();
        }
      },
      invalidatesTags: ['Cart', 'Cart_Delivery'],
    }),

    removeProductFromCart: builder.mutation<SuccessResponse, RemoveFromCartRequest>({
      query: ({ cart_id, product_id, ...args }) => {
        const body = { ...args };
        if (LocalStorageService.getItem('impersonated_customer')) {
          body.place_by_supplier = true;
          body.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/carts/${cart_id}/destroy_product_from_cart`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, requestId }) {
        dispatch(addCartPendingRequest(requestId));
        args.product_id &&
          dispatch(
            cartApi.util.updateQueryData('getCart', undefined, (draft) => {
              const cartIndexByProduct = draft.carts.findIndex((cart) => cart.products.some((prodItem) => prodItem.id === args.product_id));
              const cartProdIdx = draft.carts[cartIndexByProduct].cart_products.findIndex((el) => el.product_id === args.product_id);
              const currentProduct = draft.carts[cartIndexByProduct].cart_products[cartProdIdx];
              const prodIdx = draft.carts[cartIndexByProduct].products.findIndex((el) => el.id === args.product_id);
              draft.carts[cartIndexByProduct].cart_products.splice(cartProdIdx, 1);
              draft.carts[cartIndexByProduct].products.splice(prodIdx, 1);
              draft.carts[cartIndexByProduct].total_price -= currentProduct.total_price;
              if (draft.carts[cartIndexByProduct].cart_products.length === 0) {
                draft.carts.splice(cartIndexByProduct, 1);
              }
            }),
          );
        try {
          await queryFulfilled;
          dispatch(removeCartPendingRequest(requestId));
        } catch {
          dispatch(removeCartPendingRequest(requestId));
          dispatch(cartApi.endpoints.getCartForQuery.initiate(undefined, { forceRefetch: true })).then(({ data }) => {
            if (data && args.product_id) {
              const cartIndexByProduct = data.carts.findIndex((cart) => cart.products.some((prodItem) => prodItem.id === args.product_id));
              if (cartIndexByProduct === -1) {
                dispatch(rootApi.util.invalidateTags(['Cart']));
                return;
              }
              const cart_id = data.carts[cartIndexByProduct]?.id;
              const cart_product_id = data.carts[cartIndexByProduct].cart_products.find((el) => el.product_id === args.product_id)?.id;
              cart_product_id &&
                cart_id &&
                dispatch(
                  cartApi.endpoints.removeProductFromCart.initiate({
                    cart_id,
                    cart_product_id,
                  }),
                );
            }
          });
        }
      },
      invalidatesTags: ['Cart_Delivery'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCartQuery,
  useAddToCartMutation,
  useUpdateQuantityInCartMutation,
  useUpdateCartCommentMutation,
  useEmptyCartMutation,
  useRemoveProductFromCartMutation,
  useGetCartDeliveryFeeQuery,
} = cartApi;
