import { createStyles, makeStyles } from '@material-ui/core';

import AuthDots from '../../../assets/images/bg/Auth_bg_dots.svg';
import AuthLines from '../../../assets/images/bg/Auth_bg_lines.svg';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      minHeight: '100vh',
      height: '100%',
      padding: '50px 80px 20px 80px',
      position: 'relative',
      backgroundColor: 'rgba(207, 215, 230, 0.3)',
      background: 'linear-gradient rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 0) 0%)',
      backgroundImage: `url(${AuthLines}), url(${AuthDots})`,
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundPosition: 'top 126px left 0px, bottom 250px right 0px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        overflowY: 'scroll',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundPosition: 'top 60px left 0px, bottom 60px right 0px',
        padding: 20,
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 20,
      width: '100%',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 10,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        paddingTop: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 60,
      },
    },
    logoBox: {
      position: 'absolute',
      top: 33,
      left: 71,
      maxWidth: 300,
      maxHeight: 100,
      [theme.breakpoints.down(Sizes.desktop)]: {
        top: 15,
        left: 21,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 20,
      },
    },
    logo: {
      objectFit: 'contain',
      maxWidth: '100%',
      maxHeight: '100%',
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 50,
      },
    },
    progressWrap: {
      width: 260,
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 20,
      },
    },
    title: {
      fontSize: 40,
      lineHeight: '56px',
      fontWeight: 700,
      marginBottom: 25,
      textAlign: 'center',
      fontFamily: 'Sofia Pro',
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 36,
        marginBottom: 25,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 28,
        lineHeight: '38px',
        padding: '20px 0 0 0',
      },
    },
    subTitle: {
      opacity: 0.7,
      marginBottom: 30,
      textAlign: 'center',
      lineHeight: '23.8px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 30,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '0 30px',
      },
    },
    boldText: {
      fontWeight: 700,
      marginBottom: 25,
      width: '100%',
      textAlign: 'center',
    },
    rolesBox: {
      display: 'flex',
    },
    roleItem: {
      textAlign: 'center',
      padding: 25,
      width: 160,
      height: 160,
      backgroundColor: colorVariables.white,
      borderRadius: 10,
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      margin: '0 20px',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.2)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        margin: 0,
        '&:first-child': {
          marginRight: 15,
        },
      },
    },
    roleText: {
      fontWeight: 700,
      width: 80,
      margin: '10px auto 0 auto',
      textAlign: 'center',
    },
    btnBox: {
      justifyContent: 'center',
      marginBottom: 32,
      marginTop: 40,
      width: 500,
      display: 'grid',
      gridTemplateColumns: '1fr 230px 1fr',
      '&>*:first-child': {
        transform: 'translateX(-20px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        gridTemplateColumns: '1fr 160px 1fr',
        '&>*:first-child': {
          transform: 'translateX(5px)',
        },
      },
    },
    btnBoxVideo: {
      marginBottom: 40,
    },
    btnBoxSuppliers: {
      marginTop: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'row',
        marginBottom: 30,
        '&>button': {
          margin: '0 10px',
          height: 36,
        },
      },
    },
    notFoundText: {
      marginTop: 10,
      marginBottom: 20,
      cursor: 'pointer',
      width: '100%',
      textAlign: 'center',
      '&:hover': {
        color: colorVariables.blue,
        [theme.breakpoints.down(Sizes.mobile)]: {
          color: 'inherit',
        },
      },
    },
    fieldWrap: {
      width: 325,
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 10,
      },
    },
    smallFieldsBox: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
      width: 325,
    },
    smallFieldWrap: {
      width: '48%',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 10,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 20,
      },
    },
    formBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    deliveryInputBox: {
      marginTop: 20,
      width: 330,
      marginBottom: 30,
    },
    deliveryLabel: {
      fontSize: 12,
      marginBottom: 10,
    },
    mapImg: {
      width: 330,
      height: 275,
    },
    addLater: {
      width: '100%',
      textAlign: 'center',
      padding: '20px 0',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingBottom: 40,
      },
    },
    addLaterSuppliers: {
      marginTop: 20,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: 0,
        marginBottom: 0,
      },
    },

    productsBox: {
      width: 695,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 600,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 300,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    },
    productItem: {
      display: 'flex',
      alignItems: 'center',
      width: 330,
      height: 64,
      backgroundColor: colorVariables.white,
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      border: `1px solid ${colorVariables.transparent}`,
      borderRadius: 10,
      padding: '10px 20px',
      fontSize: 16,
      marginBottom: 13,
      cursor: 'pointer',
      transition: 'all ease-in-out 0.5s',
      '&:hover': {
        boxShadow: '1px 1px 15px 5px rgba(12, 82, 112, 0.08)',
        borderColor: colorVariables.steelGrey,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 285,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 10,
      },
    },
    productItemSelected: {
      border: `1px solid ${colorVariables.green}`,
      backgroundColor: colorVariables.greenLight,
      '& svg': {
        color: colorVariables.green,
      },
      '&:hover': {
        boxShadow: '1px 1px 15px 5px rgba(12, 82, 112, 0.08)',
      },
    },
    checkIcon: {
      marginLeft: 'auto',
      color: colorVariables.transparent,
    },
    productIcon: {
      marginRight: 10,
      display: 'flex',
      '&>svg': {
        width: 40,
        height: 40,
      },
    },
    mapWrap: {
      width: 330,
      height: 270,
    },
    reqBtnSecondary: {
      color: colorVariables.grey60,
      backgroundColor: '#EAEDF2',
      '&:hover': {
        backgroundColor: colorVariables.steelGrey,
      },
    },
    supplierPageTitle: {
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 15,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 24,
        lineHeight: '30px',
        '& br': {
          display: 'none',
        },
      },
    },
    uploadSubTitle: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        lineHeight: '27px',
      },
    },
    backToAdmin: {
      position: 'fixed',
      top: 25,
      left: 220,
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 10,
      },
    },
    supplierTypeWrap: {
      paddingTop: 23,
      display: 'grid',
      position: 'relative',
      marginBottom: 32,
      gridTemplateColumns: '147px 56px 147px',
      '&>div:nth-child(2)': {
        fontWeight: 700,
        fontSize: 20,
        textAlign: 'center',
        lineHeight: '188px',
        [theme.breakpoints.down(Sizes.mobile)]: {
          lineHeight: '138px',
        },
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        gridTemplateColumns: '130px 46px 130px',
      },
    },
    supplierTypeTile: {
      paddingTop: 36,
      height: 188,
      backgroundColor: colorVariables.white,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#4BCFBB',
      border: '2px solid #4BCFBB',
      borderRadius: 10,
      cursor: 'pointer',
      '&>span': {
        userSelect: 'none',
        paddingTop: 16,
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center',
        lineHeight: '21px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 10,
        height: 138,
        '&>svg': {
          scale: 0.7,
        },
        '&>span': {
          paddingTop: 0,
          fontSize: 16,
        },
      },
    },
    supplierTypeTileActive: {
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
      backgroundColor: colorVariables.greenLight,
    },
    recWrap: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    recommendedLabel: {
      background: 'linear-gradient(90deg, #51D9B0 0%, #33A8E2 100% )',
      color: colorVariables.white,
      fontSize: 10,
      fontWeight: 600,
      width: 95,
      height: 17,
      lineHeight: '15px',
      textAlign: 'center',
      borderRadius: '0 0 10px 10px',
    },
    supplierTypeText: {
      maxWidth: 396,
      lineHeight: '24px',
      textAlign: 'center',
      opacity: 0.7,
      marginBottom: 40,
    },
    suppliersCountBox: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20,
      transform: 'translateY(-5px)',
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 15,
      },
    },
    categoriesNumber: {
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: '#4BCFBB',
      color: colorVariables.white,
      fontSize: 14,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 10,
      '&+span': {
        fontWeight: 600,
        fontSize: 16,
      },
    },
    categorySlider: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 481,
      alignItems: 'center',
      marginBottom: 20,
      '&>svg:hover': {
        cursor: 'pointer',
        opacity: 0.7,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    categoryTile: {
      display: 'flex',
      alignItems: 'center',
      width: 320,
      height: 86,
      borderRadius: 10,
      border: '1px solid #CFD7E6',
      padding: '19px 35px',
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 240,
        padding: 15,
        margin: '0 10px',
      },
    },
    categoryIcon: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#4BCFBB',
      flexShrink: 0,
      marginRight: 17,
      color: colorVariables.white,
      '& svg': {
        scale: 1.2,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 40,
        height: 40,
        marginRight: 15,
        '& svg': {
          scale: 1,
        },
      },
    },
    catTitle: {
      fontSize: 20,
      fontWeight: 700,
      userSelect: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 16,
        lineHeight: '22px',
      },
    },
    indicatorsBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 25,
      marginBottom: 30,
      height: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        gap: 15,
      },
    },
    indicatorItem: {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'rgba(173, 173, 173, 0.19)',
      border: '2px solid rgba(97, 97, 97, 0.44)',
      transition: 'all ease-in-out 0.5s',
    },
    indicatorItemActive: {
      width: 37,
      height: 37,
      borderRadius: '50%',
      backgroundColor: colorVariables.white,
      border: '4px solid rgba(81, 217, 176, 1)',
      transition: 'all ease-in-out 0.3s',
    },
    fieldWrapMarker: {
      display: 'grid',
      gridTemplateColumns: 'auto 40px',
      alignItems: 'end',
      gap: 2,
      '& button': {
        cursor: 'pointer',
        color: colorVariables.blue,
        '&:hover': {
          opacity: 0.8,
        },
      },
    },
  }),
);
