import { useEffect, useState } from 'react';

import Cable from 'actioncable';

import { rootApi } from '../../api';
import { companyApi } from '../../api/company';
import { dashboardApi } from '../../api/dashboard';
import { OrdersDashboardResponse } from '../../api/dashboard/types';
import { ConnectionRequest } from '../../api/user/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getIsAdmin, getIsRestaurant, getMyId } from '../../store/user';
import { ToastService } from '../services/toastService';
import { TokenService } from '../services/token.service';
import { stockTakeApi } from '../../api/stocktake';

enum WSChannel {
  ConnectionRequestChannel = 'ConnectionRequestChannel',
  DashboardChannel = 'DashboardChannel',
  BackOrderToasterChannel = 'BackOrderToasterChannel',
  StocktakeChannel = 'StocktakeChannel',
}

export const useWSConnection = () => {
  const dispatch = useAppDispatch();
  const [client, setClient] = useState<Cable.Cable>();
  const userId = useAppSelector(getMyId);
  const isRestaurant = useAppSelector(getIsRestaurant);
  const isAdmin = useAppSelector(getIsAdmin);

  useEffect(() => {
    if (isAdmin) {
      return;
    }
    const token = TokenService.getToken();
    if (userId && token) {
      const consumer = Cable.createConsumer(`${process.env.REACT_APP_WS_URL}?access_token=${token}`);
      setClient(consumer);

      consumer.subscriptions.create(WSChannel.ConnectionRequestChannel, {
        connected: () => {
          console.log(`${WSChannel.ConnectionRequestChannel} connected`);
        },
        disconnected() {
          console.log(`${WSChannel.ConnectionRequestChannel} disconnected`);
        },
        received: (data: ConnectionRequest) => {
          if (isRestaurant) {
            dispatch(
              companyApi.util.updateQueryData('getConnectionRequests', undefined, (draft) => {
                const index = draft.findIndex((connection) => connection.supplier.id === data.supplier.id);
                if (index !== -1) {
                  draft[index] = data;
                } else {
                  draft.push(data);
                }
              }),
            );
            dispatch(rootApi.util.invalidateTags(['Supplier']));
          } else {
            dispatch(
              dashboardApi.util.updateQueryData('getSupplierConnectionRequests', true, (draft) => {
                if (!Array.isArray(draft)) {
                  return;
                }
                const index = draft.findIndex((connection) => connection.restaurant_company.id === data.restaurant_company.id);
                if (index !== -1) {
                  draft[index] = data;
                } else {
                  draft.push(data);
                }
              }),
            );
            dispatch(rootApi.util.invalidateTags(['Customer', 'Customers_Count']));
          }
        },
      });

      !isRestaurant &&
        consumer.subscriptions.create(WSChannel.DashboardChannel, {
          connected: () => {
            console.log(`${WSChannel.DashboardChannel} connected`);
          },
          disconnected() {
            console.log(`${WSChannel.DashboardChannel} disconnected`);
          },
          received: (data: OrdersDashboardResponse) => {
            dispatch(
              dashboardApi.util.updateQueryData('getOrdersDashboard', undefined, () => {
                return data;
              }),
            );
          },
        });

      isRestaurant &&
        consumer.subscriptions.create(WSChannel.BackOrderToasterChannel, {
          connected: () => {
            console.log(`${WSChannel.BackOrderToasterChannel} connected`);
          },
          disconnected() {
            console.log(`${WSChannel.BackOrderToasterChannel} disconnected`);
          },
          received: (data: { order_number: string; orders: string; user_id: number }) => {
            ToastService.warning(
              `[${data.orders}] has backordered a product in order [${data.order_number}]`,
              data.order_number,
              undefined,
              { autoClose: false },
            );
          },
        });

        isRestaurant &&
          consumer.subscriptions.create(WSChannel.StocktakeChannel, {
            connected: () => {
              console.log(`${WSChannel.StocktakeChannel} connected`);
            },
            disconnected() {
              console.log(`${WSChannel.StocktakeChannel} disconnected`);
            },
            received: (data: { add_variance: boolean, stocktake_id: number }) => {
              if(data.add_variance) {
                dispatch(stockTakeApi.endpoints.exportStockTake.initiate(data.stocktake_id));
              }
            },
          });
    } else {
      client?.disconnect();
      setClient(undefined);
    }
  }, [!!userId]);
};
