import { createReducer } from '@reduxjs/toolkit';

import uniq from 'lodash/uniq';
import { CreateOrderResponse, OrderFilters, OrderSortBy } from '../../api/order/types';
import { logoutThunk } from '../user';
import {
  clearCheckOffIdsForOrder,
  setCheckOffIdsForOrder,
  setOrdersKeyword,
  setOrdersPage,
  setOrdersSortFilter,
  setOrdersType,
  setSelectedOrders,
} from './orders.actions';

interface State {
  sortBy?: OrderSortBy;
  filterBy?: OrderFilters;
  keyword?: string;
  ordersType?: string;
  currentPage: number;
  successOrder?: Omit<CreateOrderResponse, 'success' | 'message'>;
  selectedOrders: { id: number; deleted: boolean }[];
  checkOffApprovedIds: number[];
  checkOffRequestedIds: number[];
}

const initialState: State = {
  sortBy: undefined,
  filterBy: undefined,
  keyword: undefined,
  ordersType: undefined,
  currentPage: 1,
  selectedOrders: [],
  checkOffApprovedIds: [],
  checkOffRequestedIds: [],
};

export const ordersReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return { ...initialState, filterBy: undefined };
  });
  builder.addCase(setOrdersKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });
  builder.addCase(setOrdersPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(setSelectedOrders, (state, { payload }) => {
    state.selectedOrders = payload;
  });
  builder.addCase(setCheckOffIdsForOrder, (state, { payload }) => {
    if (payload.approved) {
      state.checkOffApprovedIds = uniq([...state.checkOffApprovedIds, payload.approved]);
      state.checkOffRequestedIds = state.checkOffRequestedIds.filter((el) => el !== payload.approved);
    }
    if (payload.requested) {
      state.checkOffRequestedIds = uniq([...state.checkOffRequestedIds, payload.requested]);
      state.checkOffApprovedIds = state.checkOffApprovedIds.filter((el) => el !== payload.requested);
    }
  });
  builder.addCase(clearCheckOffIdsForOrder, (state) => {
    state.checkOffRequestedIds = [];
    state.checkOffApprovedIds = [];
  });
  builder.addCase(setOrdersSortFilter, (state, { payload }) => {
    state.filterBy = payload.filter;
    state.sortBy = payload.sort;
    state.currentPage = 1;
  });
  builder.addCase(setOrdersType, (state, { payload }) => {
    state.ordersType = payload;
  });
});
