import { format } from 'date-fns';

import { OrderFilters } from '../../api/order/types';
import { orderSortParams } from '../../components/AppDrawer/SupplierDrawer/Orders/OrdersList/orders-filter';
import { formatCurrency } from '../../shared/helpers/format-currency';
import { RootState } from '../index';

export const getOrdersSortFilters = (state: RootState) => ({
  sort: state.orders.sortBy,
  filter: state.orders.filterBy,
  keyword: state.orders.keyword,
  currentPage: state.orders.currentPage,
  ordersType: state.orders.ordersType,
});

export const getSuccessOrder = (state: RootState) => state.orders.successOrder;

export const getOrdersChips = (state: RootState) => {
  const sortLabel = orderSortParams.find((param) => param.value === state.orders.sortBy?.join(' '))?.label || '';

  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };

  const filterChips = Object.entries(state.orders.filterBy || {}).reduce((acc, [key, value]: [string, string]) => {
    switch (key as keyof OrderFilters) {
      case 'delivery_from_date': {
        if (!value || !state.orders.filterBy?.delivery_to_date) {
          return acc;
        }
        acc.push({
          label: `Delivery date - from ${format(new Date(value), 'd MMM yy')} to ${format(
            new Date(state.orders.filterBy.delivery_to_date),
            'd MMM yy',
          )}`,
          key: `delivery_date`,
        });
        break;
      }
      case 'order_from_date': {
        if (!value || !state.orders.filterBy?.order_to_date) {
          return acc;
        }
        acc.push({
          label: `Order date - from ${format(new Date(value), 'd MMM yy')} to ${format(
            new Date(state.orders.filterBy.order_to_date),
            'd MMM yy',
          )}`,
          key: `order_date`,
        });
        break;
      }
      case 'min_total_price': {
        if (!value) {
          return acc;
        }
        acc.push({
          label: `Order total more than ${formatCurrency(value)}`,
          key: `min`,
        });
        break;
      }
      case 'max_total_price': {
        if (!value) {
          return acc;
        }
        acc.push({
          label: `Order total less than ${formatCurrency(value)}`,
          key: `max`,
        });
        break;
      }
      case 'gst': {
        if (!Array.isArray(value)) {
          return acc;
        }
        acc.push(
          ...value.map((el) => ({
            label: `Tax - ${el === '0' ? 'No' : 'Yes'}`,
            key: `gst ${el}`,
          })),
        );
        break;
      }
      case 'stock': {
        if (!Array.isArray(value)) {
          return acc;
        }
        acc.push(
          ...value.map((el) => ({
            label: el === '2' ? 'In stock' : el === '1' ? 'Low stock' : 'Out of stock',
            key: `stock ${el}`,
          })),
        );
        break;
      }
      case 'export_status': {
        if (!Array.isArray(value)) {
          return acc;
        }
        acc.push(
          ...value.map((el) => ({
            label: el === '1' ? 'Exported' : el === '0' ? 'New order' : el === '2' ? 'Deleted' : '',
            key: `export_status ${el}`,
          })),
        );
        break;
      }
      case 'notes': {
        if (!value) {
          return acc;
        }
        acc.push({
          label: 'Written note',
          key: `notes`,
        });
        break;
      }
      case 'orders_for_delivery_today': {
        if (!value) {
          return acc;
        }
        acc.push({
          label: 'Delivery today',
          key: `today`,
        });
        break;
      }
    }
    return acc;
  }, [] as { label: string; key: string }[]);

  return [sortChip, ...filterChips].filter((chip) => !!chip.label && !!chip.key);
};

export const getSelectedOrdersIds = (state: RootState) => state.orders.selectedOrders;

export const getRequestedCheckOffIds = (state: RootState) => state.orders.checkOffRequestedIds;

export const getApprovedCheckOffIds = (state: RootState) => state.orders.checkOffApprovedIds;
